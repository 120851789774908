<template>
  <div class="inquiries-layout" v-show="hasBtn">
    <div class="inquiries-layout__logo">
      <div class="inquiries-layout__logo-body">
        <NttEast />
        <TypeABtn v-if="typeA" :form_items="form_items_list" />
      </div>
    </div>
    <main :class="`inquiries-layout__body${columns_to_class_name}`">
      <h1>{{ screen_composition.form_title }}</h1>
      <div
        :class="`inquiries-layout${columns_to_class_name}`"
        v-if="form_template == 2"
      >
        <Help type="default" :screen_composition="screen_composition" />
      </div>

      <router-view
        @btnTiming="hasBtn = !hasBtn"
        :screen_composition="screen_composition"
        :form_items="form_items_list"
        :isClicked="isClicked"
        @updateParams="updateParams"
        :formId="formId"
      />
      <div
        :class="`inquiries-layout-bottom${columns_to_class_name}`"
        v-if="!hideSubmitBtn"
      >
        <ButtonSubmit
          class="inquiries__button"
          :type="`signup${columns_to_class_name}`"
          @click.native="onClickSubmit()"
          :errorBtn="errorBtn"
        >
          送信する
        </ButtonSubmit>
      </div>
    </main>
    <FooterSimple v-show="hasBtn" />
  </div>
</template>

<script>
import NttEast from "@/components/logo/NttEast";
import FooterSimple from "@/components/common/FooterSimple";
import "@/assets/shared/sass/object/project/_inquiries.scss";
import Help from "@/components/detail/HelpArea";
import ButtonSubmit from "@/components/button/SubmitButton";
import TypeABtn from "@/components/button/TypeA";

export default {
  props: ["type"],
  name: "inquiries",
  components: {
    NttEast,
    FooterSimple,
    Help,
    ButtonSubmit,
    TypeABtn,
  },
  data() {
    return {
      form_items_list: [],
      screen_composition: {},
      screen_components: [],
      form_template: 0,
      postParams: {},
      isClicked: false,
      allValid: false,
      hasBtn: false,
      errorBtn: false,
      cvpage_redirect_url: "",
      isRequested: false,
      form_redirect_url: "",
      hideSubmitBtn: false,
      formId: "",
    };
  },
  computed: {
    columns_to_class_name() {
      if (this.form_template == 2) {
        return "--2column";
      } else {
        return "";
      }
    },
    typeA() {
      return !!this.$route.name.match("inquiriesTypeA");
    },
  },
  methods: {
    post: function () {
      if(document.querySelector("input[name='RandomID']")){
        this.postParams.form_items.RandomID = document.querySelector("input[name='RandomID']").value;
      }
      return this.$axios.post("form", this.postParams);
    },
    onClickSubmit: function () {
      this.isClicked = true;

      if (!this.allValid) {
        this.errorBtn =
          "入力内容に不備があります。必須項目や入力条件をご確認いただき、再度送信を行ってください。";
        return;
      }

      if (this.isRequested) {
        return;
      }

      this.isRequested = true;

      const router = this.$router;
      let query = new URLSearchParams(this.$route.query);

      if (this.$route.query.type == "A") {
        this.post()
          .then(() => {
            if (this.cvpage_redirect_url) {
              return (window.location.href = this.cvpage_redirect_url);
            }
            router.push({ path: "/cvpagea?" + query });
          })
          .catch((err) => {
            this.$logwarn(err);
            router.push({ name: "errorSystem" });
          });
      } else {
        this.post()
          .then(() => {
            if (this.cvpage_redirect_url) {
              return (window.location.href = this.cvpage_redirect_url);
            }
            router.push({ path: "/cvpage?" + query });
          })
          .catch((err) => {
            this.$logwarn(err);
            router.push({ name: "errorSystem" });
          });
      }
    },
    updateParams: function (postParams, allValid) {
      this.postParams = postParams;
      this.allValid = allValid;
    },
  },
  async mounted() {
    if (!this.$route.query.formId) {
      this.$router.push({ name: "error404" });
      return;
    }

    await this.$axios
      .get("form", {
        params: { formId: this.$route.query.formId, page_type: 1 },
      })
      .then((response) => {
        this.screen_components = response.data;
        this.screen_composition = response.data.screen_composition;
        this.form_items_list = response.data.form_items;
        this.form_template = response.data.screen_composition.form_template;
        this.cvpage_redirect_url =
          response.data.screen_composition.cvpage_redirect_url;
        this.form_redirect_url =
          response.data.screen_composition.form_redirect_url;

        this.$nextTick(() => {
          // SHA256ハッシュ化関数のPromise版定義

          function sha256(str) {
            // Convert string to ArrayBuffer
            var buff = new Uint8Array(
              [].map.call(str, function (c) {
                return c.charCodeAt(0);
              })
            ).buffer;

            // Calculate digest
            return crypto.subtle.digest("SHA-256", buff).then(function (digest) {
              // Convert ArrayBuffer to hex string
              return [].map
                .call(new Uint8Array(digest), function (x) {
                  return ("00" + x.toString(16)).slice(-2);
                })
                .join("");
            });
          }

          // タイムスタンプを生成する関数
          function createTimestamp() {
            var now = new Date();

            var year = now.getFullYear();
            var month = String(now.getMonth() + 1).padStart(2, "0");
            var day = String(now.getDate()).padStart(2, "0");
            var hours = String(now.getHours()).padStart(2, "0");

            return year + month + day + hours;
          }

          // input要素を取得。Idの値は書き換える。
          //// メールアドレスの入力フォーム
          var ga4id_queryselector_nodelist_email = document.querySelectorAll(
            "input[name='email']"
          );
          var ga4id_queryselector_array_email = Array.prototype.slice.call(
            ga4id_queryselector_nodelist_email
          );
          var gacrm_mail_input = ga4id_queryselector_array_email[0];

          // 変数の宣言
          //// メールアドレスを格納する
          var gacrm_mail_text = "(not set)";

          //// メールアドレスのハッシュ化を格納する
          var gacrm_mail_text_hash = "(not set)";

          // //// メールアドレスのドメインを格納する
          // var gacrm_mail_text_domain = "(not set)";

          ////// 初期値取得
          if(document.querySelector("input[name='email']") != null && gacrm_mail_input != null){

            gacrm_mail_text = document.querySelector("input[name='email']").value;
            sha256(gacrm_mail_text + "a8jsna810lsn").then(function (result) {
              gacrm_mail_text_hash = result;
            });

            //// タイムスタンプの作成
            var gacrm_timestamp = createTimestamp();

            //// 初期値の入力とセッションストレージへの書き込み
            var ga4id_queryselector_nodelist_ga4id = document.querySelectorAll(
              "input[name='RandomID']"
            ); //////////////////////////////////////////////セレクタの差し替え必要！！！！！！！！！！！！！！！！！！！！！！！！
            var ga4id_queryselector_array_ga4id = Array.prototype.slice.call(
              ga4id_queryselector_nodelist_ga4id
            );
            ga4id_queryselector_array_ga4id[0].setAttribute(
              "value",
              gacrm_timestamp + "_" + gacrm_mail_text_hash.slice(0, 15)
            );
            sessionStorage.setItem(
              "random_id",
              gacrm_timestamp + "_" + gacrm_mail_text_hash.slice(0, 15)
            );

            // メールアドレスの入力フォームを参照。セッションストレージへの書き込みとフォームへの自動入力。
            gacrm_mail_input.addEventListener("input", function () {
              // フォームの値を取得
              gacrm_mail_text = gacrm_mail_input.value;

              // メールアドレスのハッシュ化
              sha256(gacrm_mail_text + "a8jsna810lsn").then(function (result) {
                gacrm_mail_text_hash = result;

                sessionStorage.setItem(
                  "random_id",
                  gacrm_timestamp + "_" + gacrm_mail_text_hash.slice(0, 15)
                );

                // 隠し項目への自動入力
                var ga4id_queryselector_nodelist_ga4id = document.querySelectorAll(
                  "input[name='RandomID']"
                ); //////////////////////////////////////////////セレクタの差し替え必要！！！！！！！！！！！！！！！！！！！！！！！！
                var ga4id_queryselector_array_ga4id = Array.prototype.slice.call(
                  ga4id_queryselector_nodelist_ga4id
                );
                ga4id_queryselector_array_ga4id[0].setAttribute(
                  "value",
                  gacrm_timestamp + "_" + gacrm_mail_text_hash.slice(0, 15)
                );
              });
            });
          }
        });
      })
      .catch((err) => {
        this.$logwarn(err);
        this.$router.push({ name: "error404" });
      });

    const form_text = this.screen_composition.form_text;
    const form_info_title = this.screen_composition.form_info_title;
    const form_info_text = this.screen_composition.form_info_text;
    const form_info_image = this.screen_composition.form_info_image;
    const form_info_bgcolor = this.screen_composition.form_info_bgcolor;
    this.formId = this.$route.query.formId;

    if (
      form_text == "" &&
      form_info_title == "" &&
      form_info_text == "" &&
      form_info_image == "" &&
      form_info_bgcolor == ""
    ) {
      this.hideSubmitBtn = true;
    }
    // 直接$route.queryの値を書き換えないよう、一度JSONに変換したものを変数に保存しJSONから戻す
    let query = JSON.stringify(this.$route.query);
    query = JSON.parse(query);
    delete query.formId;
    query.type = "A";
    let excFormId = new URLSearchParams(query);
    excFormId = excFormId.toString();

    if (this.typeA) {
      return (this.hasBtn = true);
    } else if (this.form_redirect_url.includes("/a?formId")) {
      return (window.location.href = this.form_redirect_url + "&" + excFormId);
    } else if (this.form_redirect_url) {
      return (window.location.href = this.form_redirect_url);
    }
    this.hasBtn = true;
  },
};
</script>

<style lang="scss" scoped>
.button-link--signup {
  margin: 46px auto 0 auto;
  @media screen and (max-width: $sp_point) {
    margin-top: 78px;
  }
}
.button-link--signup--2column {
  margin: 46px 70px 0 auto;
  @media screen and (max-width: $sp_point) {
    margin: 12px auto 0;
  }
}
h1 {
  width: 100%;
  display: block;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.92;
  text-align: center;
  color: #333;
  margin: 60px 0;
  @media screen and (max-width: $sp_point) {
    font-size: 2.2rem;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 22px;
    margin-top: 0;
    margin-bottom: 25px;
  }
}
.inquiries-layout__logo-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1100px;
  height: 80px;
  margin: 0 auto;
  @media screen and (max-width: $tablet_point) {
    width: auto;
    height: 56px;
    padding: 0 15px;
  }
}

.inquiries-layout__body {
  padding-bottom: 95px;
  width: 1100px;
  margin: 0 auto;
  @media screen and (max-width: $tablet_point) {
    width: auto;
  }
}
.inquiries-layout__body--2column {
  @extend.inquiries-layout__body;
  display: flex;
  flex-wrap: wrap;
}
.inquiries-layout--2column {
  width: 530px;
  margin: 0 auto;
  @media screen and (max-width: $tablet_point) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: $sp_point) {
    width: 100%;
  }
}
.inquiries-layout-bottom--2column {
  width: 530px;
  margin: 0 0 0 auto;
  @media screen and (max-width: $tablet_point) {
    margin: 0 auto 20px;
  }
  @media screen and (max-width: $sp_point) {
    width: 100%;
  }
}
.inquiries-layout__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-top: 1px solid $c-border;
}
.inquiries-layout__copyright {
  font-size: 1.4rem;
  font-weight: 300;
}
</style>
