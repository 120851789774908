<template>
  <div :class="[className, prevClass]">
    <component :is="tag" :href="href" :target="isNewTab ? '_blank' : undefined" class="button-link__body">
      <slot></slot>
      <Arrow class="button-link__icon" :type="arrowName" />
    </component>
  </div>
</template>
<script>

import Arrow from '@/components/arrow/Link';

export default {
  name: 'ButtonMore',
  props: [ 'href', 'type', 'prev', 'isNewTab' ],
  components: {
    Arrow
  },
  computed: {
    tag() {
      return this.href ? 'a' : 'span';
    },
    className() {
      return this.type ? `button-link--${ this.type }` : 'button-link';
    },
    prevClass() {
      return this.prev ? 'is-prev' : '';
    },
    arrowName() {
      const type = this.type;
      if(type === 'signup' || type === 'primary') {
        return 'tertiary';
      } else {
        return type;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.button-link {
  position: relative;
  width: 400px;
  height: 60px;
  @media screen and (max-width: $sp_point) {
    width: 300px;
  }
  &:hover{
    .button-link__body {
      text-decoration: none;
    }
  }
}
.button-link--signup-narrow {
  @extend .button-link;
  width: 100%;
  max-width: 300px;
  height: 40px;
  margin: auto;
  .button-link__body {
    font-size: 16px;
    font-weight: normal;
    background-color: $c-white;
    color: #0074bf;
    border: 1px solid #0074bf;
    transition: .2s;
    &:hover{
      color: #0099e4;
      background-color: $c-white;
      border-color: #0099e4;
      text-decoration: none;
    }
  }
}
.button-link--login-narrow {
  @extend .button-link;
  width: 100%;
  max-width: 300px;
  height: 40px;
  margin: auto;
  .button-link__body {
    font-size: 16px;
    font-weight: normal;
    background-color: #0074bf;
    color: $c-white;
    border: 1px solid #0074bf;
    transition: .2s;
    &:hover{
      background-color: #0099e4;
      border-color: #0099e4;
    }
  }
}
.button-link--secondary {
  @extend .button-link;
  .button-link__body {
    background-color: transparent;
    color: $c-secondary;
    border-color: $c-secondary;
  }
}
.button-link--tertiary {
  @extend .button-link;
  .button-link__body {
    background-color: transparent;
    color: $c-white;
    border-color: $c-white;
  }
}

.button-link--signup {
  @extend .button-link;
  .button-link__body {
     background-color: #0074bf;
     border-radius: 4px;
    color: $c-white;
    border: 0;
    transition: .2s;
    &:hover{
      text-decoration: none;
      background-color: #0099e4;
      border-color: #0099e4;
    }
  }
}
.button-link--dark {
  @extend .button-link;
  .button-link__body {
    background-color: $c-onsite-button;
    color: $c-white;
    border: 0;
  }
}
.button-link__body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid $c-primary;
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
  background-color: #0074bf;
  color: $c-white;
  border: 0;
  border-radius: 4px;
  transition: .2s;
  @media screen and (max-width: $sp_point) {
    font-size: 1.6rem;
  }
  &:hover{
      background-color: #0099e4;
      border-color: #0099e4;
      text-decoration: none;
  }
}
.button-link__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto;
  transform-origin: center center;
}
.is-prev {
  .button-link__icon {
    right: auto;
    left: 20px;
    transform: scale(-1, 1);
  }
}
</style>
