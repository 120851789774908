<template>
  <div id="app" class="app">
    <!-- Google Tag Manager (noscript) -->
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-TD73K2F"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe
    ></noscript>
    <!-- End Google Tag Manager (noscript) -->
    <component :is="layout"></component>
  </div>
</template>

<script>
import InquiriesLayout from "./layout/Inquiries.vue";
import InquiriesFinishLayout from "./layout/InquiriesFinish.vue";
import Inquiries1columnLayout from "./layout/Inquiries1column.vue";
import Inquiries2columnLayout from "./layout/Inquiries2column.vue";
import NoneLayout from "./layout/None.vue";
import DefaultLayout from "./layout/Default.vue";

export default {
  components: {
    InquiriesLayout,
    Inquiries1columnLayout,
    Inquiries2columnLayout,
    NoneLayout,
    DefaultLayout,
    InquiriesFinishLayout,
  },
  computed: {
    layout() {
      let layout = this.$route.meta.layout
        ? this.$route.meta.layout + "-layout"
        : "default-layout";
      return layout;
    },
  },
  head: {
    meta: [
      {
        "http-equiv": "Content-Security-Policy",
        content: "upgrade-insecure-requests",
      },
      { name: "description", content: "NTT東日本のフォームです。", id: "desc" },
      {
        property: "og:image",
        content:
          "https://form.business.ntt-east.co.jp/assets/shared/img/common/ntt_form_ogp.png",
      },
    ],
    script: function () {
      return [
        {
          type: "text/javascript",
          async: true,
          inner: (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
              "gtm.start": new Date().getTime(),
              event: "gtm.js",
            });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "GTM-TD73K2F"),
        },
      ];
    },
    link: [
      {
        type: "image/svg+xml",
        rel: "icon",
        href: require("./assets/shared/img/common/favicon.svg"),
      },
      {
        rel: "apple-touch-icon",
        href: require("./assets/shared/img/common/icon.png"),
      },
    ],
  },
};
</script>

<style lang="scss" scoped>
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Noto+Sans:wght@400;700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&family=Noto+Sans:wght@400;700&display=swap");
// #app {
//   min-width: 1240px;
//   font-family: 'Noto Sans JP', sans-serif;
//   @media screen and (max-width: $sp_point_mf) {
//     min-width: 0;
//   }
// }
</style>
