<template>
  <p :class="className">
    <slot></slot>
  </p>
</template>

<script>
export default {
  props: [
    'type'
  ],
  computed: {
    className() {
      return this.type ? `error-message--${ this.type }` : 'error-message';
    }
  }
}
</script>

<style lang="scss" scoped>
%text {
  margin-bottom: -8px;
  font-size: 1.4rem;
  line-height: 1.93;
  color: #cb0101;
}
.error-message{
  @extend %text;
}
.form--checkboxes{
  +.error-message{
    margin-top: -8px;
    @media screen and (max-width: $sp_point) {
      margin-top: 0;
    }
  }
}
.form--radiobuttons {
  +.error-message{
    margin-top: 0;
  }
}
</style>