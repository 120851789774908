<template>
  <div class="form-checkbox">
    <input 
      type="checkbox" 
      :name="name" 
      :id="id" 
      class="form-checkbox__input"
      :checked="checked"
      @change="$emit('change', $event.target.checked)"
    />
    <label :for="id" class="form-checkbox__label" :class="[stateName]">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      placeholder: "",
      value: "",
      required: "",
      
    };
  },
  name: "FormCheckBox",
  props: {
    checked: {
      type: Boolean,
    },
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    state: {
      type: String,
    },
    form_items: {
      type: String,
    },
  },
  computed: {
    stateName() {
      return this.state ? `is-${this.state}` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.form-checkbox {
  padding-bottom: 10px;
  @include last-child() {
    @media screen and (max-width: $sp_point) {
      padding-bottom: 0;
    }
  }
}
.form-checkbox__input {
  position: absolute;
  opacity: 0;
  &:checked {
    & ~ .form-checkbox__label {
      &::before {
        background-color: #006cff;
        border-color: #006cff;
      }
      &::after {
        display: block;
        border-color: #fff;
      }
    }
  }
}
.form-checkbox__label {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin: 0;
  font-size: 1.6rem;
  line-height: 1.5;
  @media screen and (max-width: $sp_point) {
    // white-space: nowrap;
  }
  &::before {
    content: "";
    display: inline-block;
    border-radius: 2px;
    position: absolute;
    top: 1px;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    background-color: #fcfcfc;
    border: solid 1px #ccc;
  }
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 2px);
    left: 9px;
    width: 16px;
    height: 9px;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: rotate(-50deg);
    transform-origin: left bottom;
  }
  &.is-error {
    &::before {
      border-color: #cb0101;
      background-color: $c-errorBg;
    }
    &::after {
      border-color: $c-errorBg;
    }
  }
}
.is-nowrap {
  .form-checkbox__label {
    white-space: nowrap;
  }
}
.form {
  position: relative;
}
.form__label {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 1.6rem;
  color: $c-text;
  &::before,
  &::after {
    display: inline-block;
    content: "";
  }
  &::before {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    background-color: $c-lightGray;
    border: solid 1px #ccc;
  }
  &::after {
    position: absolute;
    display: none;
  }
}
%error--check {
  &.is-error {
    .form__label {
      &::before {
        background-color: #ffe8e8;
        border: solid 1px $c-error;
      }
    }
  }
  .text-error {
    padding-top: 8px;
  }
}
// チェックボックス
.form--checkbox {
  @extend .form;
  @extend %error--check;
  .form__label {
    &::after {
      bottom: 4px;
      left: 9px;
      width: 16px;
      height: 9px;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      transform: rotate(-50deg);
      transform-origin: left bottom;
    }
  }
}
// チェックタイプの基本スタイル
.form__input-check {
  position: absolute;
  opacity: 0;
  &:checked {
    & ~ .form__label {
      &::before {
        background-color: #006cff;
        border-color: #006cff;
      }
      &::after {
        display: block;
      }
    }
  }
}
</style>
