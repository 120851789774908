<template>
  <img :src="require(`@/assets/shared/img/${ dir }/${ name }.${ extension }`)"
     :width="width" :height="height" :alt="alt">
</template>

<script>
export default {
  name: 'Retina',
  props: {
    'dir': {type: String},
    'name': {type: String},
    'extension': {type: String},
    'width': {type: String},
    'height': {type: String},
    'alt': {type: String, default: ''}
  }
}
</script>

<style lang="scss" scoped>
img {
  vertical-align: top;
}
</style>