<template>
  <div class="inquiries">
    <Header type="finish" :text="thx_screen_composition.thx_text">
      <template v-slot:title>{{ thx_screen_composition.thx_title }}</template>
    </Header>
    <!-- htmlで受け付けるならヘッダー不要？
    <div class="inquiries__bottom-wrapper">
      <ButtonLink class="inquiries__button" type="to" :href="NTT_EID_URL"
        >NTT東日本 法人のお客様トップへ</ButtonLink
      >
    </div>
    -->
  </div>
</template>
<script>
import Header from "@/components/common/HeaderInquiries";
// import ButtonLink from "@/components/button/Link";
import "@/assets/shared/sass/object/project/_inquiries.scss";

export default {
  head: {
    title: function () {
      return {
        inner: this.form_title + "　" + "お問い合わせ完了",
        separator: "|",
        complement: "NTT東日本",
      };
    },
    meta: [
      { property: "og:title", content: "お問い合わせ完了|NTT東日本" },
      { name: "twitter:title", content: "お問い合わせ完了|NTT東日本" },
    ],
    link: function () {
      return [
        {
          rel: "canonical",
          href: `https://form.business.ntt-east.co.jp/cvpagea?formId=${
            this.formId ?? ""
          }`,
          id: "canonical",
        },
      ];
    },
    script: function () {
      if (this.setUgattrProp) {
        return [
          {
            type: "text/javascript",
            async: true,
            body: true,
            inner: function () {
              window.ug_prop01 = this.ug_array.prop01;
              window.ug_prop02 = this.ug_array.prop02;
            },
          },
          (this.setUgattrProp = false),
        ];
      }
    },
  },
  name: "FormFinish",
  props: ["type", "form_title", "ug_array", "formId"],
  data: function () {
    return {
      thx_screen_composition: {
        thx_template: "",
        thx_title: "",
        thx_text: "",
      },
      NTT_EID_URL: process.env.VUE_APP_NTT_EID_URL,
      setUgattrProp: false,
    };
  },

  components: {
    Header,
    // ButtonLink,
  },

  async created() {
    if (!this.$route.query.formId) {
      this.$router.push({ name: "error404" });
      return;
    }

    // ブラウザリロード時の処理を設定
    window.addEventListener("beforeunload", this.isReloadCookieSet);

    await this.$axios
      .get("form", {
        params: { formId: this.$route.query.formId, page_type: 2 },
      })
      .then((response) => {
        this.thx_screen_composition.thx_text =
          response.data.thx_screen_composition.thx_text;
        this.thx_screen_composition.thx_title =
          response.data.thx_screen_composition.thx_title;
      })
      .catch((err) => {
        this.$logwarn(err);
        this.$router.push({ name: "error404" });
      });
    this.setQuery();
  },
  destroyed() {
    // ブラウザリロード時の処理を破棄
    window.removeEventListener("beforeunload", this.isReloadCookieSet);
  },
  methods: {
    isReloadCookieSet() {
      this.$cookies.set("isReload", "true");
    },
    setQuery() {
      let query = new URLSearchParams(this.$route.query);
      return query;
    },
    setUgattr() {
      window.ug_prop01 = this.ug_array.prop01;
      window.ug_prop02 = this.ug_array.prop02;
      window.dataLayer.push({ event: "ug_prop_set_event" });
      this.setUgattrProp = true;
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "inquiriesTypeA") {
      next();
    } else {
      next((vm) => {
        next({
          path: "/a?" + vm.setQuery(),
        });
      });
    }
    return;
  },
  watch: (function () {
    let watch = {
      form_title: function () {
        this.$emit("updateHead");
      },
      formId: function () {
        this.$emit("updateHead");
      },
      ug_array: {
        handler: function () {
          this.setUgattr();
          this.$emit("updateHead");
        },
        deep: true,
      },
    };
    return watch;
  })(),
};
</script>

<style lang="scss" scoped>
.text--to-top {
  text-align: center;
  font-size: 18px;
}
</style>
