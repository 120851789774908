<template>
  <input
    type="date"
    :class="['form-date', stateName]"
    :value="value"
    @input="$emit('input', $event.target.value)"  
    max="9999-12-31"
  >
</template>

<script>
export default {
  props: [
    'state',
    'value'
  ],
  computed: {
    stateName() {
      return this.state ? `is-${ this.state }` : '';
    }
  }
}
</script>

<style lang="scss" scoped>
.form-date {
  width: 100%;
  height: 40px;
  border: 1px solid $c-border-2;
  font-size: 1.6rem;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (max-width: $sp_point) {
    width: 100%;
  }
  &.is-error {
    background-color: $c-errorBg;
    border-color: #cb0101;
  }
  &:disabled{
    background-color: #ebebeb;
  }
  @media screen and (max-width: $sp_point) {
    width: 100%;
  }
}

</style>