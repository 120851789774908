<template>
  <div :class="className">
    <div class="error-btn" v-if="errorBtn">
      <ErrorMessage v-if="errorBtn">
        {{ errorBtn }}
      </ErrorMessage>
    </div>
    <button type="submit" class="button-link__body" :disabled="disabled">
      <slot></slot>
    </button>
  </div>
</template>
<script>
import ErrorMessage from "@/components/text/ErrorMessage";

export default {
  name: "ButtonSubmit",
  props: ["type", "disabled", "errorBtn"],
  computed: {
    className() {
      return this.type ? `button-link--${this.type}` : "button-link";
    },
  },
  components: {
    ErrorMessage,
  },
};
</script>
<style lang="scss" scoped>
.button-link {
  position: relative;
  width: 400px;
  height: 60px;
  @media screen and (max-width: $sp_point) {
    width: 300px;
  }
  &:hover {
    .button-link__body {
      text-decoration: none;
    }
  }
}
.button-link--signup-narrow {
  @extend .button-link;
  width: 100%;
  max-width: 300px;
  height: 40px;
  margin: auto;
  .button-link__body {
    font-size: 16px;
    font-weight: normal;
    background-color: $c-white;
    color: #0074bf;
    border: 1px solid #0074bf;
    transition: 0.2s;
    &:hover {
      color: #0099e4;
      background-color: $c-white;
      border-color: #0099e4;
      text-decoration: none;
    }
    &:disabled {
      background-color: #909090;
      border-color: #909090;
      cursor: not-allowed;
    }
  }
}
.button-link--login-narrow {
  @extend .button-link;
  width: 100%;
  max-width: 300px;
  height: 40px;
  margin: auto;
  .button-link__body {
    font-size: 16px;
    font-weight: normal;
    background-color: #0074bf;
    color: $c-white;
    border: 1px solid #0074bf;
    transition: 0.2s;
    &:hover {
      background-color: #0099e4;
      border-color: #0099e4;
    }
    &:disabled {
      background-color: #909090;
      border-color: #909090;
      cursor: not-allowed;
    }
  }
}
.button-link--secondary {
  @extend .button-link;
  .button-link__body {
    background-color: transparent;
    color: $c-secondary;
    border-color: $c-secondary;
  }
}
.button-link--tertiary {
  @extend .button-link;
  .button-link__body {
    background-color: transparent;
    color: $c-white;
    border-color: $c-white;
  }
}

.button-link--signup {
  @extend .button-link;
  .button-link__body {
    background-color: #0074bf;
    border-radius: 4px;
    color: $c-white;
    border: 0;
    transition: 0.2s;
    &:hover {
      text-decoration: none;
      background-color: #0099e4;
      border-color: #0099e4;
    }
    &:disabled {
      background-color: #909090;
      border-color: #909090;
      cursor: not-allowed;
    }
  }
}
.button-link--signup--2column {
  @extend .button-link;
  .button-link__body {
    background-color: #0074bf;
    border-radius: 4px;
    color: $c-white;
    border: 0;
    transition: 0.2s;
    &:hover {
      text-decoration: none;
      background-color: #0099e4;
      border-color: #0099e4;
    }
    &:disabled {
      background-color: #909090;
      border-color: #909090;
      cursor: not-allowed;
    }
  }
}
.button-link--dark {
  @extend .button-link;
  .button-link__body {
    background-color: $c-onsite-button;
    color: $c-white;
    border: 0;
  }
}
.button-link__body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid $c-primary;
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
  background-color: #0074bf;
  color: $c-white;
  border: 0;
  border-radius: 4px;
  transition: 0.2s;
  margin-top: 20px;
  @media screen and (max-width: $sp_point) {
    font-size: 1.6rem;
  }
  &:hover {
    background-color: #0099e4;
    border-color: #0099e4;
    text-decoration: none;
  }
  &:disabled {
    background-color: #909090;
    border-color: #909090;
    cursor: not-allowed;
  }
}
.error-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
  margin-top: -46px;
  @media screen and (max-width: $sp_point) {
    margin-bottom: 6px;
    margin-top: -99px;
  }
}
</style>
