<template>
  <picture :class="className">
    <img :src="url"  :width="width" :height="height" :alt="alt" />
  </picture>
</template>
<script>
export default {
  name: 'S3image',
  props: {
    'className': {type: String},
    'url': {type: String},
    'width': {type: String},
    'height': {type: String},
    'alt': {type: String, default: ''}
  }
}
</script>

<style lang="scss" scoped>
img {
  text-align: center;
  width: auto;
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}
</style>