<template>
  <label :for="id" class="form-text__label">
    <slot>氏</slot>
  </label>
</template>
<script>
export default {
  name: "FormLabel",
  props: ["state", "id", "form_items"],
  methods: {
    updateValue: function (e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>



<style lang="scss" scoped>
.form-text__label{
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #333;
  margin-bottom: 0;
  padding-bottom: 12px;
}
.label--require{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    padding: 0 8px;
    font-size: 1.2rem;
    color: #fff;
    border-radius: 3px;
    color: #eb5b5b;
    background-color: #fff;
    border: 1px solid #eb5b5b;
    margin-right: 5px;
}
</style>