<template>
<div class="signup">
      <div class="signup__body">
        <p class="signup__desc">「NTT東日本ビジネスID」をお持ちの方は、自動的にお客さま情報が入力され、お問い合わせや資料ダウンロードを簡単に実施いただけます。</p>
        <ButtonLink class="inquiries__button" type="login-narrow" :href="loginUrlAddParams" @click.native="setFormId">NTT東日本ビジネスIDにログイン</ButtonLink>
        <ButtonLink class="inquiries__button" type="signup-narrow" :href="signupUrl" :isNewTab="true">新規登録する</ButtonLink>
        <Whats/>
      </div>
    </div>
</template>
<script>
import ButtonLink from '@/components/button/Link';
import Whats from '@/components/logo/Whats';
export default {
  name: 'Signup',
  components: {
    ButtonLink,
    Whats
  },
  props: [
    'loginUrl'
  ],
  data() {
    return {
      loginUrlAddParams:'',
      "signupUrl": process.env.VUE_APP_NTT_EID_URL + 'NTTIDPFOnG01/usrsig003/usrsig00301'
    };
  },
  mounted() {
    this.loginUrlAddParams = this.loginUrl
  },
  methods: {
    setFormId(){
      this.$cookies.set('formId', this.$route.query.formId);
      
      const query = new URLSearchParams(this.$route.query);
      this.$cookies.set('query', query.toString());
    }
  }
};
</script>

<style lang="scss" scoped>
.signup{
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #CCC;
  .inquiries__button,.link--q{
    margin-top: 10px;
  }
}
.signup__body{
  background-color: #f0f0f0;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: $sp_point) {
    margin: 0 16px;
  }
}
.signup__desc{
  font-size: 16px;
  line-height: 1.63;
  padding-bottom: 10px;
  width: 100%;
}
</style>
