<template>
  <div class="inquiries-layout">
    <div class="inquiries-layout__logo">
      <div class="inquiries-layout__logo-body">
        <NttEast />
      </div>
    </div>
    <main class="inquiries-layout__body">
      <router-view
        :form_title="form_title"
        :ug_array="ug_array"
        :formId="formId"
      />
    </main>
    <FooterSimple />
  </div>
</template>

<script>
import NttEast from "@/components/logo/NttEast";
import FooterSimple from "@/components/common/FooterSimple";
import "@/assets/shared/sass/object/project/_inquiries.scss";

export default {
  name: "InquiriesFinish",
  components: {
    NttEast,
    FooterSimple,
  },
  computed: {},
  data: function () {
    return {
      thx_screen_composition: {
        thx_template: "",
        thx_title: "",
        thx_text: "",
      },
      form_title: "",
      ug_array: {
        prop01: "",
        prop02: "",
      },
      formId: "",
    };
  },
  methods: {},
  async mounted() {
    if (!this.$route.query.formId) {
      this.$router.push({ name: "error404" });
      return;
    }
    this.formId = this.$route.query.formId;
    this.ug_array.prop01 = this.$cookies.get("prop01");
    this.ug_array.prop02 = this.$cookies.get("prop02");
    this.$cookies.remove("prop01");
    this.$cookies.remove("prop02");

    await this.$axios
      .get("form", {
        params: { formId: this.$route.query.formId, page_type: 1 },
      })
      .then((response) => {
        this.form_title = response.data.screen_composition.form_title;
      })
      .catch((err) => {
        this.$logwarn(err);
        this.$router.push({ name: "error404" });
      });
  },
};
</script>

<style lang="scss" scoped>
.inquiries-layout__logo-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1100px;
  height: 80px;
  margin: 0 auto;
  @media screen and (max-width: $tablet_point) {
    width: auto;
    height: 56px;
    padding: 0 15px;
  }
}

.inquiries-layout__body {
  padding-bottom: 95px;
  width: 1100px;
  margin: 0 auto;
  @media screen and (max-width: $tablet_point) {
    width: auto;
    padding-bottom: 80px;
  }
}
.inquiries-layout__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-top: 1px solid $c-border;
}
.inquiries-layout__copyright {
  font-size: 1.4rem;
  font-weight: 300;
}
</style>

<style lang="scss"></style>
