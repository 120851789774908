<template>
  <div class="help">
    <div class="help__body" :class="className">
      <h2 class="help__form_info_title">{{screen_composition.form_info_title}}</h2>
      <S3image
        :url="screen_composition.form_info_image"
        width="475"
        height="490"
      />
      <div class="help__contents" v-html="screen_composition.form_info_text">
        <!--
        {{screen_composition.form_info_text}}
        <br>
        <strong>【電話サービスのご契約者さまはこちら】</strong> <br>
        ＞<a href="" class="help__anchor">契約確認について</a><br>
        ＞<a href="" class="help__anchor">料金関連について</a><br>
        ＞<a href="" class="help__anchor">解約等について</a>
        -->
      </div>
    </div>
  </div>
</template>
<script>
import S3image from '@/components/img/S3image';
export default {
  props: [
          "type", 
          "imgtype",
          "screen_composition"
          ],
  name: "help",
  components: {
    S3image,
  },
  computed: {
    imgName() {
      return this.imgtype ? `dummy475x490${this.type}` : "dummy475x490";
    },
    className() {
      return this.type ? `help__body--${this.type}` : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.help__contents{
  width: 100%;
}
.help__form_info_title{
  width: 100%;
}
.help__body {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &--default {
    background-color: #e4f3fa;
  }
  &--primary {
    background-color: antiquewhite;
  }
  &--secondary {
    background-color: darkgrey;
  }
}
.help {
  font-size: 16px;
  line-height: 1.63;
  img {
    max-width: 490px;
    width: 100%;
    height: auto;
    margin: auto;
    margin-bottom: 30px;
    @media screen and (max-width: $sp_point) {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
  h1,
  h2 {
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    @media screen and (max-width: $sp_point) {
      margin-bottom: 20px;
      font-size: 18px;
    }
  }
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }
  a {
    padding-left: 10px;
    display: inline-block;
    color: #0074bf;
    text-decoration: underline;
    &:hover {
      color: #f49000;
    }
  }
}
</style>
