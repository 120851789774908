<template>
<div class="typeA-btn">
  <div class="login-btn">
      <ButtonLink v-if="$store.state.userinfo.sub && form_items[0]" class="logout-btn__inquiries" type="signup-narrow" @click.native="logout">ログアウト</ButtonLink>
      <ButtonLink v-else-if="form_items[0]"  class="login-btn__inquiries" type="login-narrow"  :href="loginUrlAddParams" @click.native="setFormId"><p class="login-btn-msg-pc">ログインで簡単お問い合わせ</p><p class="login-btn-msg-sp">ログイン</p></ButtonLink>
  </div>
</div>
</template>

<script>
import ButtonLink from '@/components/button/Link';
export default {
  name: 'NttEast',
  props: [ 'type','form_items' ],
  data() {
    return {
      loginUrlAddParams:'',
      "signupUrl": process.env.VUE_APP_NTT_EID_URL + 'NTTIDPFOnG01/usrsig003/usrsig00301'
    };
  },
  computed: {
  },
  components: {
    ButtonLink
  },
  mounted(){
    let routeQuery = this.$route.query;
    routeQuery.type = 'A';
    let query = new URLSearchParams(routeQuery);
    let encodeQuery = encodeURIComponent(query.toString());
  
    this.$cookies.set('query', query.toString());

    // stateをCookieに追加
    const state = this.$uuid.generate()
    this.$cookies.set('state', state);
    this.$cookies.set('formId', this.$route.query.formId);
    // ログインURL生成
    this.loginUrlAddParams =
      process.env.VUE_APP_BARISTA_LOGIN_URL + 'auth/realms/nttidpf_user/protocol/openid-connect/auth' +
      '?client_id=' + process.env.VUE_APP_BARISTA_CLIENT_ID +
      '&response_type=code' +
      '&scope=openid+profile+email+address+phone+given_name_kana+family_name_kana+company_name' +
      '&state=' + state +
      '&redirect_uri=' + process.env.VUE_APP_HOST_URL + 'callback?'+ encodeQuery 
    window.addEventListener('beforeunload', this.isReloadCookieSet);
  },
  methods: {
    setFormId(){
      this.$cookies.set('formId', this.$route.query.formId);
      const query = new URLSearchParams(this.$route.query);
      this.$cookies.set('query', query.toString());
    },

    logout() {
      // ブラウザリロード時の処理を破棄
      window.removeEventListener('beforeunload', this.isReloadCookieSet);

      // ログインフラグをfalseに設定
      this.$cookies.set('isLogin', 'false');
      sessionStorage.setItem('isLogin', 'false');
      // let query = new URLSearchParams(this.$route.query);

      let routeQuery = this.$route.query;
      routeQuery.type = 'A';
      let query = new URLSearchParams(routeQuery);
      let encodeQuery = encodeURIComponent(query.toString());

      this.$cookies.set('query', query.toString());

      // ログアウトURLを生成
      let logoutUrl =
      process.env.VUE_APP_BARISTA_LOGIN_URL + 'auth/realms/nttidpf_user/protocol/openid-connect/logout' +
      '?id_token_hint=' + this.$store.state.userinfo.id_token +
      '&state=' + this.$cookies.get('state') +
      '&post_logout_redirect_uri=' + process.env.VUE_APP_HOST_URL + 'callback?' + encodeQuery
      // ログアウトURLへ遷移
      window.location.href = logoutUrl
    },
    isReloadCookieSet() {
      this.$cookies.set('isReload', 'true')
    },
  },
  destroyed() {
    // ブラウザリロード時の処理を破棄
    window.removeEventListener('beforeunload', this.isReloadCookieSet);
  },
}
</script>

<style lang="scss" scoped>
.typeA-btn {
  width: 100%;
  height: 32px;
  @media screen and (max-width: $sp_point) {
    width: 160px;
    height: 28px;
  }
}
.inquiries__button {
  width: 300px;
}
.login-btn-msg-pc{
  @media screen and (max-width: $sp_point) {
  display: none;
  }
}
.login-btn-msg-sp{
  display: none;
  @media screen and (max-width: $sp_point) {
    display:block;
  }

}
.login-btn__inquiries {
  margin-right: 0;
  width: 300px;
  @media screen and (max-width: $sp_point) {
    width: 160px;
    height: 28px;
  }
}
.logout-btn__inquiries {
  margin-right: 0;
  width: 126px;
  @media screen and (max-width: $sp_point) {
    width: 160px;
    height: 28px;
  }
}

</style>