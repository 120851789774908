<template>
  <i :class="className"></i>
</template>

<script>
export default {
  props: [
    'type'
  ],
  computed: {
      className() {
        return this.type ? `icon-arrow-link--${ this.type }` : 'icon-arrow-link';
      }
    }
}
</script>

<style lang="scss" scoped>
.icon-arrow-link {
  display: inline-block;
  width: 20px;
  height: 7px;
  background-image: url(~@/assets/shared/img/common/arw-link-primary.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.icon-arrow-link--secondary {
  @extend .icon-arrow-link;
  background-image: url(~@/assets/shared/img/common/arw-link-secondary.svg);
}
// .icon-arrow-link--tertiary {
//   @extend .icon-arrow-link;
//   background-image: url(~@/assets/shared/img/common/arw-link-tertiary.svg);
// }
.icon-arrow-link--dark {
  @extend .icon-arrow-link;
  background-image: url(~@/assets/shared/img/common/arw-link-tertiary.svg);
}
.icon-arrow-link--anchor-red {
  @extend .icon-arrow-link;
  background-image: url(~@/assets/shared/img/common/arw-anchor-red.svg);
}
.icon-arrow-link--left {
  @extend .icon-arrow-link;
  background-image: url(~@/assets/shared/img/common/arw-anchor-left.svg);
}
</style>