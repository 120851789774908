export default {
  namespaced: true,
  state: {
    test: 0,
    sub: "",
    address: {
      street_address: "",
      locality: "",
      postal_code: "",
      region: ""
    },
    email_verified: false,
    given_name: "",
    updated_at: 0,
    phone_number: "",
    family_name: "",
    email: ""
  },
  mutations: {
    addTest(state, n) {
      state.test += n
    },
    updateUserinfo(state, userinfo) {
      state.sub = userinfo.sub

      state.address.street_address = userinfo.address.street_address
      state.address.locality = userinfo.address.locality
      state.address.postal_code = userinfo.address.postal_code
      state.address.region = userinfo.address.region

      state.email_verified = userinfo.email_verified
      state.given_name = userinfo.given_name
      state.given_name_kana = userinfo.given_name_kana
      state.updated_at = userinfo.updated_at
      state.phone_number = userinfo.phone_number
      state.family_name = userinfo.family_name
      state.family_name_kana = userinfo.family_name_kana
      state.email = userinfo.email
      state.company_name = userinfo.company_name
      state.uid = userinfo.uid
      state.id_token = userinfo.id_token
    },
    deleteUserinfo(state) {
      state.sub = ""

      state.address.street_address = ""
      state.address.locality = ""
      state.address.postal_code = ""
      state.address.region = ""

      state.email_verified = false
      state.given_name = ""
      state.given_name_kana = ""
      state.updated_at = 0
      state.phone_number = ""
      state.family_name = ""
      state.family_name_kana = ""
      state.email = ""
      state.company_name = ""
      state.uid = ""
      state.id_token = ""
    }
  },
}
