<template>
  <!-- htmlが渡された場合 -->
  <p :class="className" v-html="html" v-if="html">
  </p>
  <!-- そうでない場合 -->
  <p :class="className" v-else>
    <slot></slot>
  </p>
</template>

<script>
export default {
  props: [
    'type',
    'html'
  ],
  computed: {
    className() {
      return this.type ? `text--${ this.type }` : 'text';
    }
  }
}
</script>

<style lang="scss" scoped>
%text {
  @include text-crop;
  font-size: 1.6rem;
  line-height: 1.69;
  color: inherit;
}
.text{
  @extend %text;
  &+.text {
    padding-top: 1em;
  }
}
.text--middle {
  @extend %text;
  font-size: 1.8rem;
  @media screen and (max-width: $sp_point) {
    font-size: 1.6rem;
  }
}
.text--lead {
  @extend %text;
  padding-top: 30px;
  @media screen and (max-width: $sp_point) {
    padding-top: 20px;
  }
}
</style>