<template>
  <div class="inquiries">
    <Header>
      <template v-slot:title>
        法人のお客さまご相談・お問い合わせフォーム
      </template>
      <template v-slot:text>
        下記のフォームに必要事項をご入力の上、「送信」ボタンを押してください。お問い合わせ内容につきましては、後日、担当者よりご連絡いたします。<br />
        お問い合わせ内容によっては、お時間を頂戴する場合がございます。ご了承ください。（冒頭自由テキスト）
      </template>
    </Header>
    <SignUp />
    <div class="inquiries__section">
      <h3 class="title-label--required">
        <p class="label--require">必須</p>
        お問い合わせ内容（複数選択可）
      </h3>
      <div>
        <div class="form--checkboxes">
          <div class="form__check-wrap">
            <FormCheckBox name="checkbox1" id="checkbox1_1">サービス導入検討のため</FormCheckBox>
          </div>
          <div class="form__check-wrap">
            <FormCheckBox name="checkbox1" id="checkbox1_2">サービス比較検討のため</FormCheckBox>
          </div>
          <div class="form__check-wrap">
            <FormCheckBox name="checkbox1" id="checkbox1_3">サービス調査のため</FormCheckBox>
          </div>
        </div>
      </div>
    </div>
    <div class="inquiries__section">
      <h3 class="title-label--required">お問い合わせ内容 詳細</h3>
      <div>
        <FormTextArea state="" class="inquiries__textarea" />
      </div>
    </div>
    <div class="inquiries__section">
      <div class="form__group">
        <div class="form__item">
          <p class="label--require">必須</p>
          <FormLabel for="FamilyName">氏 </FormLabel>
          <FormText name="name" id="FamilyName" type="text" value="中村" disabled required />
        </div>
        <div class="form__item">
          <p class="label--require">必須</p>
          <FormLabel for="GivenName">名 </FormLabel>
          <FormText name="name" id="GivenName" type="text" value="太郎" disabled required />
        </div>
      </div>
    </div>
    <div class="inquiries__section">
      <div class="form__group">
        <div class="form__item">
          <p class="label--require">必須</p>
          <FormLabel for="FamilyNameKana">氏（カナ） </FormLabel>
          <FormText name="name" id="FamilyNameKana" type="text" value="ナカムラ" disabled required />
        </div>
        <div class="form__item">
          <p class="label--require">必須</p>
          <FormLabel for="GivenNameKana">名（カナ） </FormLabel>
          <FormText name="name" id="GivenNameKana" type="text" value="タロウ" disabled required />
        </div>
      </div>
    </div>
    <div class="inquiries__section">
      <div class="form__item">
        <p class="label--require">必須</p>
        <FormLabel for="email">メールアドレス </FormLabel>
        <FormText name="email" id="email" type="email" value="nakamura@example.com" disabled required />
      </div>
    </div>
    <div class="inquiries__section">
      <div class="form__item">
        <p class="label--require">必須</p>
        <FormLabel for="tel">電話番号 </FormLabel>
        <FormText name="tel" id="tel" type="tel" value="09000000000" disabled required />
      </div>
    </div>
    <div class="inquiries__section">
      <div class="form__item">
        <p class="label--require">必須</p>
        <FormLabel for="company_name">会社名 </FormLabel>
        <FormText name="company_name" id="company_name" type="text" value="XXX株式会社" disabled required />
      </div>
    </div>
    <div class="inquiries__section">
      <h3 class="title-label--required">
        <p class="label--require">必須</p>
        業種
      </h3>
      <FormSelect :option-list="optionList" :disabled="true"></FormSelect>
    </div>
    <div class="inquiries__section">
      <h3 class="title-label--required">
        <p class="label--require">必須</p>日付選択
      </h3>
      <FormDate :disabled="true"> </FormDate>
    </div>
    <div class="inquiries__section">
      <h3 class="title-label--required">
        <p class="label--require">必須</p>
        ラジオボタン質問
      </h3>
      <div class="form--radiobuttons">
        <div class="form__check-wrap">
          <FormRadio name="radio1" id="radio1_1">テキスト</FormRadio>
        </div>
        <div class="form__check-wrap">
          <FormRadio name="radio1" id="radio1_2">テキスト</FormRadio>
        </div>
        <div class="form__check-wrap">
          <FormRadio name="radio1" id="radio1_3">テキスト</FormRadio>
        </div>
      </div>
    </div>
    <div class="inquiries__section">
      <h3 class="title-label--required">
        <p class="label--require">必須</p>
        個人情報の取り扱い
      </h3>
      <div class="privacy">
        <div class="privacy__body">
          <p class="privacy__text">
            当社は、お客様の個人情報を、<a class="privacy__anchor"
              href="https://business.ntt-east.co.jp/formpolicy.html">「お問い合わせ等フォームにおける個人情報の取り扱い」</a>に定めるところに基づき取り扱い、営業上のご案内（広告・宣伝のメールマガジン含む）を行う場合があります。
          </p>
        </div>
        <div class="privacy__foot">
          <div class="privacy__check">
            <FormCheckBox name="privacy" id="privacy">同意します</FormCheckBox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/common/HeaderInquiries2";
import FormTextArea from "@/components/form/TextArea";
import FormCheckBox from "@/components/form/CheckBox";
import FormRadio from "@/components/form/Radio";
import FormText from "@/components/form/Text";
import FormSelect from "@/components/form/Select";
import FormDate from "@/components/form/Date";
import FormLabel from "@/components/form/label";
import SignUp from "@/components/detail/signupAfter";

export default {
  head: {
    title: {
      inner: '[フォームタイトル]',
      separator: '|',
      complement: 'NTT東日本'
    },
    meta: [
      { property: "og:title", content: "[フォームタイトル]|NTT東日本" },
      { name: "twitter:title", content: "[フォームタイトル]|NTT東日本" },
    ],
    link: [
      {
        rel: "canonical",
        href: "https://form.business.ntt-east.co.jp/?formId=フォームid",
        id: "canonical",
      },
    ],
  },
  name: "FormInput2After",
  props: ["type"],
  components: {
    Header,
    FormTextArea,
    FormCheckBox,
    FormRadio,
    FormText,
    FormLabel,
    FormSelect,
    SignUp,
    FormDate
  },
  data() {
    return {
      optionList: [
        { value: "1", text: "オプション1" },
        { value: "2", text: "オプション2" },
        { value: "3", text: "オプション3" },
        { value: "4", text: "オプション4" },
        { value: "5", text: "オプション5" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.inquiries {
  width: 530px;
  margin: 0 auto;

  @media screen and (max-width: $sp_point) {
    width: 100%;
    margin-top: 22px;
  }
}

.privacy {
  width: 100%;
  height: auto;
  position: relative;
  overflow: auto;
  border: solid 1px #ccc;
  padding: 12px 20px 20px;
}

.privacy__body {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #ccc;
}

.privacy__text {
  font-size: 16px;
  color: #333;
  line-height: 1.69;
}

.privacy__anchor {
  color: #0074bf;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.privacy__check {
  display: flex;
  justify-content: center;
}

.title-label--required {
  display: inline-block;
  padding: 0 0 12px;
  font-weight: bold;
  font-size: 1.4rem;
}
</style>
