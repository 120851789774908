<template>
  <div class="inquiries">
    <Header>
      <template v-slot:title>404エラー</template>
      <template v-slot:subtitle> ページが見つかりませんでした </template>
      <template v-slot:text>
        ご指定のページが見つかりませんでした。<br />
        ページが存在しないか、移動もしくは削除されているものと思われます。
      </template>
    </Header>
    <div class="inquiries__bottom-wrapper--primary">
      <ButtonLink
        class="inquiries__button"
        type="to"
        :href="NTT_BUSINESS_TOP_URL"
        >NTT東日本 法人のお客様トップへ</ButtonLink
      >
    </div>
  </div>
</template>
<script>
import Header from "@/components/common/HeaderError";
import ButtonLink from "@/components/button/Link";
import "@/assets/shared/sass/object/project/_inquiries.scss";

export default {
  head: {
    title: {
      inner: "404エラー",
      separator: "|",
      complement: "NTT東日本",
    },
    meta: [
      { property: "og:title", content: "404エラー | NTT東日本" },
      { name: "twitter:title", content: "404エラー | NTT東日本" },
    ],
    link: [
      {
        rel: "canonical",
        href: "https://form.business.ntt-east.co.jp/error404",
        id: "canonical",
      },
    ],
  },
  name: "Error404",
  props: ["type"],
  components: {
    Header,
    ButtonLink,
  },
  data: function () {
    return {
      NTT_BUSINESS_TOP_URL: process.env.VUE_APP_NTT_BUSINESS_TOP_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
.text--to-top {
  text-align: center;
  font-size: 18px;
}
.inquiries__button {
  width: 300px;
  height: 50px;
  .button-link__body {
    font-weight: normal;
  }
}
</style>
