import Vue from 'vue'
// import VueRouter from 'vue-router'
import VueHead from 'vue-head'
import App from './App.vue'
import store from './store'
import router from './router'
import "@/assets/shared/sass/style.scss";
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'
import "url-search-params-polyfill";

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
import ConstantsPlugin from './plugins/constants'
Vue.use(ConstantsPlugin)
import AxiosPlugin from './plugins/axios'
Vue.use(AxiosPlugin)
import LoggerPlugin from './plugins/logger'
Vue.use(LoggerPlugin)
import UUIDPlugin from './plugins/uuid'
Vue.use(UUIDPlugin)

Vue.config.productionTip = false
Vue.config.devtools = process.env.VUE_APP_DEVTOOLS == 'true'

Vue.use(VueHead, {
  inner: '',
  separator: '|',
  complement: 'NTT東日本'
})

// Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )
// エラーハンドリング
Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.VUE_APP_CONSOLE_LOGGING == 'true') {
    console.error(`Captured in Vue.config.errorHandler: ${info}`, err)
  }

  if (process.env.VUE_APP_DEBUG != 'true') {
    router.replace({ name: 'errorSystem' })
  }
}

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
