import axios from 'axios'

// Axiosの共通設定を行うプラグイン
const AxiosPlugin = {}

AxiosPlugin.install = function (Vue) {
  Vue.prototype.$axios = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'json',
    crossDomain: true,
    timeout: 5000,
    // withCredentials: true // Cookieの送信を許可
  })

  // リクエストログ
  Vue.prototype.$axios.interceptors.request.use(
    function (config) {
      if (process.env.VUE_APP_CONSOLE_LOGGING == 'true') {
        console.info('[info]', config)
      }
      return config
    },
    function (error) {
      if (process.env.VUE_APP_CONSOLE_LOGGING == 'true') {
        console.error('[error]', error)
      }
      return Promise.reject(error)
    }
  )

  // レスポンスログ
  Vue.prototype.$axios.interceptors.response.use(
    function (response) {
      if (process.env.VUE_APP_CONSOLE_LOGGING == 'true') {
        console.info('[info]', response)
      }
      return response
    },
    function (error) {
      if (process.env.VUE_APP_CONSOLE_LOGGING == 'true') {
        console.error('[error]', error)
      }
      return Promise.reject(error)
    }
  )
}

export default AxiosPlugin
