const LoggerPlugin = {}

LoggerPlugin.install = function (Vue) {
  Vue.prototype.$loginfo = function (message) {
    if (process.env.VUE_APP_CONSOLE_LOGGING == 'true') {
      console.log('[info]', message)
    }
  }
  Vue.prototype.$logwarn = function (message) {
    if (process.env.VUE_APP_CONSOLE_LOGGING == 'true') {
      console.warn('[warn]', message)
    }
  }
  Vue.prototype.$logerror = function (message) {
    if (process.env.VUE_APP_CONSOLE_LOGGING == 'true') {
      console.error('[error]', message)
    }
  }
}

export default LoggerPlugin
