<template>
  <div class="form-radio">
    <input
      type="radio"
      :name="name"
      :id="id"
      class="form-radio__input"
      :value="val"
      @change="$emit('input', $event.target.value)"  
    />
    <label :for="id" class="form-radio__label" :class="[stateName]">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
    data() {
    return {
      placeholder:"",
      required:""
    };
  },
  name: "Radio",
  props: [
    'name',
    'id',
    "state",
    "form_items",
    "val"  // 理由は不明だが名前をvalueにするとうまく動かなかった
  ],
  computed: {
    stateName() {
      return this.state ? `is-${this.state}` : "";
    },
  },
}
</script>

<style lang="scss" scoped>
.form-radio {
  padding-bottom: 10px;
  @include last-child() {
    padding-bottom: 0;
  }
}
.form-radio__input {
  position: absolute;
  opacity: 0;
  &:checked {
    & ~ .form-radio__label {
      &::after {
        opacity: 1;
      }
    }
  }
}
.form-radio__label {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 32px;
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: bold;
  @media screen and (max-width: $sp_point) {
    // white-space: nowrap;
  }
  &::before {
    content: "";
    display: block;
    border: 1px solid $c-border-2;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 1px;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  &::after {
    content: "";
    display: block;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 4px;
    top: 1px;
    bottom: 0;
    margin: auto;
    opacity: 0;
    background-color: $c-primary;
    border-radius: 50%;
  }
  &.is-error {
    &::before {
      border-color: #cb0101;
      background-color: $c-errorBg;
    }
  }
}
</style>
