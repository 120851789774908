<template>
  <div class="inquiries">
    <Header>
      <template v-slot:title>
        {{ screen_composition.form_title }}
      </template>
      <template v-slot:text>
        {{ screen_composition.form_text }}
      </template>
    </Header>
    <SignUp />
    <div v-for="(form_items, index) in form_items_list" :key="index">

      <!-- テンプレート -->
      <template>
        <div class="inquiries__section">
          <p v-if="form_items.requiredflag" class="label--require">必須</p>
          <h3 class="title-label--required">
            {{ form_items.name_label }}
          </h3>

          <FormTextArea v-if="form_items.input_type === 5" state="" class="inquiries__textarea"
            v-model="$data['form' + String(index + 1) + 'text']" />

          <FormText v-else-if="form_items.input_type === 1" :name="form_items.name_class" :id="form_items.name_class"
            :required="form_items.requiredflag" v-model="$data['form' + String(index + 1) + 'text']" />

          <FormSelect v-else-if="form_items.input_type === 4" :option-list="form_items.options"
            v-model="$data['form' + String(index + 1) + 'text']" :value="$data['form' + String(index + 1) + 'text']" />

          <FormDate v-else-if="form_items.input_type === 6" />
          <div class="form__check-wrap" v-else-if="form_items.input_type === 2">
            <FormRadio v-for="(option, index) in form_items.options" :key="option.index" :name="form_items.name_class"
              :id="form_items.name_class + String(index + 1)" v-model="$data['form' + String(index + 1) + 'text']"
              :value="$data['form' + String(index + 1) + 'text']">
              {{ option }}
            </FormRadio>
          </div>

          <div class="form--checkboxes" v-else-if="form_items.input_type === 3">
            <div class="privacy" v-if="form_items.name_class === 'PersonalInfoConsentCheck'">
              <div class="privacy__body">
                <p class="privacy__text">
                  当社は、お客様の個人情報を、<a class="privacy__anchor"
                    href="https://business.ntt-east.co.jp/formpolicy.html">「お問い合わせ等フォームにおける個人情報の取り扱い」</a>に定めるところに基づき取り扱い、営業上のご案内（広告・宣伝のメールマガジン含む）を行う場合があります。
                </p>
              </div>
              <div class="privacy__foot">
                <div class="privacy__check">
                  <FormCheckBox name="privacy" id="privacy"
                    :checked="$data['form' + String(index + 1) + 'checkboxes'][0]"
                    @change="$data['form' + String(index + 1) + 'checkboxes'].splice(0, 1, $event)">
                    同意します
                  </FormCheckBox>
                </div>
              </div>
            </div>

            <div class="form__check-wrap" v-else>
              <FormCheckBox v-for="(option, index_) in form_items.options" :key="option" name="checkbox1"
                :id="`checkbox${index}_${index_}`" :checked="$data['form' + String(index + 1) + 'checkboxes'][index_]"
                @change="$data['form' + String(index + 1) + 'checkboxes'].splice(index_, 1, $event)">
                {{ option }}
              </FormCheckBox>
            </div>
          </div>
          <ErrorMessage v-if="errorMessages[index]">{{ errorMessages[index] }}</ErrorMessage>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import Header from "@/components/common/HeaderInquiries2";
import FormTextArea from "@/components/form/TextArea";
import FormCheckBox from "@/components/form/CheckBox";
import FormRadio from "@/components/form/Radio";
import FormText from "@/components/form/Text";
import FormSelect from "@/components/form/Select";
// import FormLabel from "@/components/form/label";
import FormDate from "@/components/form/Date"
import SignUp from "@/components/detail/signup";
import ErrorMessage from "@/components/text/ErrorMessage";

export default {
  head: {
    title: {
      inner: '[フォームタイトル]',
      separator: '|',
      complement: 'NTT東日本'
    },
    meta: [
      { property: "og:title", content: "[フォームタイトル]|NTT東日本" },
      { name: "twitter:title", content: "[フォームタイトル]|NTT東日本" },
    ],
    link: [
      {
        rel: "canonical",
        href: "https://form.business.ntt-east.co.jp/?formId=フォームid",
        id: "canonical",
      },
    ],
  },
  name: "FormInput2",
  props: [
    "type",
  ],
  components: {
    Header,
    FormTextArea,
    FormCheckBox,
    FormRadio,
    FormText,
    // FormLabel,
    FormDate,
    FormSelect,
    SignUp,
    ErrorMessage
  },
  data() {
    //各フォームのdataと同じ文のvalidation用のdataを準備している
    const data = {
      disabled: false,
      screen_composition: [],
      form_items_list: [],
      allValid: false,
      validations: {
        form1: false,
        form2: false,
        form3: false,
        form4: false,
        form5: false,
        form6: false,
        form7: false,
        form8: false,
        form9: false,
        form10: false
      },
      errorMessages: {
        form1: false,
        form2: false,
        form3: false,
        form4: false,
        form5: false,
        form6: false,
        form7: false,
        form8: false,
        form9: false,
        form10: false
      },
      isLoggedIn: undefined
    }
    // 三種類のフォーム計30このdataを用意している
    for (let i = 0; i < 12; i++) {
      data['form' + String(i + 1) + 'text'] = ""
      data['form' + String(i + 1) + 'checkboxes'] = [false, false, false, false, false, false, false, false, false, false, false, false]
    }

    return data
  },
  mounted() {
    this.$axios
      .get('form?formId=sample001111inq&page_type=1')
      .then(response => {
        this.screen_composition = response.data.screen_composition

        for (let i = 0; i < response.data.form_items.length; i++) {
          let item = response.data.form_items[i]
          if (item.input_type !== 7) {
            this.form_items_list.push(item)
          }
        }

        this.validationCheck()
      }).catch({})
  },
  methods: {
    validationCheck() {
      for (let i = 0; i < this.form_items_list.length; i++) {
        let form_item = this.form_items_list[i]

        if ([1, 2, 4].includes(form_item.input_type)) {
          this.validations['form' + String(i + 1)] = this['form' + String(i + 1) + 'text'] !== ""
        } else if (form_item.name_class === 'PersonalInfoConsentCheck') {
          this.validations['form' + String(i + 1)] = this['form' + String(i + 1) + 'checkboxes'][0] === true
        } else if (this.form_items_list[i].input_type === 3) {
          this.validations['form' + String(i + 1)] = this['form' + String(i + 1) + 'checkboxes'].includes(true)
        }
      }

      this.allValid = false // TODO

      return
    },
  },
  watch: function () {
    let watch = {}

    for (let i = 0; i < 12; i++) {
      watch['form' + String(i + 1) + 'text'] = function () {
        this.validationCheck()
      }
      watch['form' + String(i + 1) + 'checkboxes'] = function () {
        this.validationCheck()
      }
    }

    return watch
  }()
};
</script>

<style lang="scss" scoped>
.inquiries {
  width: 530px;
  margin: 0 auto;

  @media screen and (max-width: $sp_point) {
    width: 100%;
    margin-top: 22px;
  }
}

.privacy {
  width: 100%;
  height: auto;
  position: relative;
  overflow: auto;
  border: solid 1px #ccc;
  padding: 12px 20px 20px;
}

.privacy__body {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #ccc;
}

.privacy__text {
  font-size: 16px;
  color: #333;
  line-height: 1.69;
}

.privacy__anchor {
  color: #0074bf;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.privacy__check {
  display: flex;
  justify-content: center;
}

.title-label--required {
  display: inline-block;
  padding: 0 0 12px;
  font-weight: bold;
  font-size: 1.4rem;
}
</style>
