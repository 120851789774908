<template>
  <div class="default-layout">
    <!-- <p class="test"><router-link to="/">テストページ遷移用</router-link></p> -->
    <router-view />
  </div>
</template>

<script>
// import Header from '@/components/common/Header';
// import Alarm from '@/components/alarm/Default';

export default {
  name: 'default',
  components: {

  },
  computed: {},
  data() {
    return {}
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.test {
  font-size: 2rem;
}
</style>
