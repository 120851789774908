<!--<template>
  <input type="text" :class="['form-text', stateName]">
</template>

<script>
export default {
  props: [
    'state'
  ],
  computed: {
    stateName() {
      return this.state ? `is-${ this.state }` : '';
    }
  }
}
</script>-->

<template>
  <!-- TODO maxlengthの実装 -->
  <input
    type="text"
    :value="value"
    :class="['form-text', stateName]"
    :name="name"
    :id="id"
    :placeholder="placeholder"
    :required="required"
    @input="$emit('input', $event.target.value)"  
    :maxlength="maxLengthComputed" 
  />
</template>

<script>
export default {
  name: "FormText",
    data() {
    return {
      id: "",
      name:"",
      placeholder:"",
      required:""
    };
  },
  props: {
    state: String,
    form_items: Object,
    value: [String, Number],
    maxlength: Number
  },
  computed: {
    stateName() {
      return this.state ? `is-${this.state}` : "";
    },
    maxLengthComputed() {
      return this.maxlength ? this.maxlength : 100
    }
  },
  methods: {
    updateValue: function (e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-text {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  border: 1px solid $c-border-2;
  font-size: 1.6rem;
  &.is-error {
    background-color: $c-errorBg;
    border-color: #cb0101;
  }
  &:disabled {
    background-color: #ebebeb;
  }
  @media screen and (max-width: $sp_point) {
    width: 100%;
  }
}
</style>
