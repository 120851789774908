<template>
  <div class="ntteast">
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: '',
      state: '',
      isType: Boolean,
    }
  },
  created: function(){
    this.$loginfo('visit callback');

    // 更新ボタン押下時に二重でエンドポイントにリダイレクトしないようフラグをfalseにセット
    this.$cookies.set('isReload', 'false')
    let query = this.$cookies.get('query');
    this.isType = this.$route.query.type;
    // &prompt=noneでコールバックした際にerror=login_requiredがクエリに含まれていれば未ログイン
    if (this.$route.query.error && this.$route.query.error == 'login_required') {
      this.$loginfo('not login');

      this.$cookies.set('isLogin', 'false');
      sessionStorage.setItem('isLogin', 'false');
      if(this.isType == 'A'){
        return this.$router.replace({path: '/a?' + query})
      }
      return this.$router.replace({path: '/?' + query})
    }

    // Get Parameter
    this.code = this.$route.query.code
    this.state = this.$route.query.state

    this.$loginfo(this.code)
    this.$loginfo(this.state)

    /* state の値に不整合があった場合、以下の処理でログイン前 or 後の画面に振り分けられるのでコメントアウト */
    // let myState = this.$cookies.get('state')

    // if (myState !== this.state) {
    //   // 不正な遷移
    //   this.$logwarn('invalid state.')

    //   this.$cookies.set('isLogin', 'false');
    //   sessionStorage.setItem('isLogin', 'false');
    //   return this.$router.replace({path: '/?' + query})
    // }

    // ログアウトなどで code の値が存在しない場合はログイン前へ遷移
    if (!this.code) {
      this.$logwarn('code not found.')

      this.$cookies.set('isLogin', 'false');
      sessionStorage.setItem('isLogin', 'false');
      if(this.isType == 'A'){
        return this.$router.replace({path: '/a?' + query})
      }
        return this.$router.replace({path: '/?' + query})
    }

    let q = new URLSearchParams(this.$route.query);
    let qq = encodeURIComponent(q.toString());
    this.$loginfo('query:' + qq);
    this.$loginfo('redirect_uri:' + process.env.VUE_APP_HOST_URL + 'callback?' + query);

    // Userinfo Request
    this.$axios.post('userinfo', {
      code: this.code,
      redirect_uri: process.env.VUE_APP_HOST_URL + 'callback?' + query,
    })
    .then(response => {
      // 取得成功

      const result = response.data
      // Storeにセット
      this.$loginfo('set userinfo')
      this.$store.commit('userinfo/updateUserinfo', result)

      // uidをCookieに保存
      this.$loginfo('set uid in cookie')
      this.$cookies.set('uid', this.$store.state.userinfo.uid, 7200);

      // ログイン時に別ウィンドウを開いた際もログイン状態にするためにCookieとセッションストレージにセット
      // Cookieに存在し、セッションストレージに存在しない場合は別ウィンドウで開いたと判定可能
      this.$cookies.set('isLogin', 'true');
      sessionStorage.setItem('isLogin', 'true');
      if(this.isType == 'A'){
        return this.$router.replace({path: '/a?' + query})
      }
      this.$router.replace({path: '/?' + query})
    }).catch(err => {
      this.$loginfo(err.response)
      this.$logerror(err.response)
      // 取得失敗
      this.$cookies.set('isLogin', 'false');
      sessionStorage.setItem('isLogin', 'false');
      if(this.isType == 'A'){
        return this.$router.replace({path: '/a?' + query})
      }
      this.$router.replace({path: '/?' + query})
    })
  },
}
</script>
