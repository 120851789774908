<template>
  <footer class="global-footer" :class="className">
    <ul class="global-footer__links">
    <li class="global-footer__links-item">
      <a href="https://business.ntt-east.co.jp/requirements.html" target="_blank" rel="noopener">サイトのご利用条件</a>
    </li>
    <li class="global-footer__links-item">
      <a href="https://www.ntt-east.co.jp/policy/" target="_blank" rel="noopener">プライバシーポリシー</a>
    </li>
    <li class="global-footer__links-item">
      <a href="https://business.ntt-east.co.jp/tokusho.html" target="_blank" rel="noopener">特別商取引法表記</a>
    </li>
  </ul>
  <p class="global-footer__copyright"><small>Copyright &copy; 2021 東日本電信電話株式会社 All Rights Reserved.</small></p>
  </footer>
</template>


<script>

export default {
  props: [ 'type' ],
  components: {
  },
  computed: {
    className() {
      return this.type ? `footer--${ this.type }` : 'footer';
    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
  background-color: $c-footerBg;
}
.footer__top {
  background-color: $c-white;
}
.footer__browser {
  @include text-crop;
  width: 1100px;
  margin: 0 auto;
  padding-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.75;
  @media screen and (max-width: $sp_point) {
    width: auto;
    padding: 0 20px 17px;
    font-size: 1.2rem;
  }
}
.footer__body {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 60px 0;
  width: 1100px;
  margin: 0 auto;
  @media screen and (max-width: $sp_point) {
    width: auto;
    flex-direction: column;
    padding: 30px 0;
  }
}
.footer__search-input {
  @media screen and (max-width: $sp_point) {
    padding: 0 20px;
  }
}
.footer__link {
  display: flex;
  padding-top: 40px;
  @media screen and (max-width: $sp_point) {
    padding: 30px 0;
    flex-direction: column;
  }
}
.footer__link-item {
  @media screen and (max-width: $sp_point) {
    border-bottom: 1px solid rgba($c-white, 0.35);
    @include first-child() {
      border-top: 1px solid rgba($c-white, 0.35);
    }
  }
  @include after-first(1) {
    margin-left: 36px;
    @media screen and (max-width: $sp_point) {
      margin-left: 0;
    }
  }
}
.footer__sns {
  width: 490px;
  padding: 22px 30px 27px;
  border: 1px solid rgba($c-white, 0.35);
  @media screen and (max-width: $sp_point) {
    width: calc(100% - 50px);
    margin: 0 auto;
    padding: 20px;
  }
}
.footer__sns-body {
  display: flex;
  align-items: center;
  @media screen and (max-width: $sp_point) {
    flex-direction: column;
  }
}
.footer__sns-title {
  font-size: 1.6em;
  font-weight: bold;
  color: $c-white;
  padding-right: 26px;
  @media screen and (max-width: $sp_point) {
    padding-right: 0;
  }
}
.footer__sns-list {
  display: flex;
  align-items: center;
  @media screen and (max-width: $sp_point) {
    padding-top: 20px;
  }
}
.footer__sns-item {
  @include after-first(1) {
    margin-left: 20px;
  }
}
.footer__sns-note {
  font-size: 1.4em;
  line-height: 1.6;
  color: $c-white;
  padding-top: 15px;
}
.footer__bottom {
  padding: 40px 0;
  background-color: $c-white;
  @media screen and (max-width: $sp_point) {
    padding: 30px 13px 25px;
  }
}
.footer__bottom-link {
  display: flex;
  justify-content: center;
  @media screen and (max-width: $sp_point) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.footer__bottom-link-item {
  position: relative;
  padding: 0 12px;
  &::before {
    content: '';
    display: block;
    background-color: $c-text;
    width: 1px;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  @include last-child {
    &::before {
      display: none;
    }
  }
  @include after-first(1) {
    @media screen and (max-width: $sp_point) {
      margin-top: 10px;
    }
  }
  a {
    font-size: 1.4rem;
    &[target='_blank'] {
      &::after {
        content: '';
        display: inline-block;
        width: 9px;
        height: 9px;
        background-image: url(~@/assets/shared/img/common/icon-blank.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 6px;
      }
    }
  }
}
.footer__copyright {
  padding-top: 30px;
  text-align: center;
  font-size: 1.4rem;
}
//ここから
.global-footer {
  padding: 0 0 20px;
  background-color: $c-white;
}

.global-footer__links {
  display: flex;
  justify-content: center;
  color: $c-black;
  @media screen and (max-width: $sp_point) {
    flex-wrap: wrap;
    padding: 0 40px;
  }
}

.global-footer__links-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 30px;
  @include after-first(1) {
    border-left: 1px solid #ccc;
  }
  @media screen and (max-width: $sp_point) {
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    padding: 0;
    @include after-first(1) {
      border: 0;
    }
    @include after-first(2) {
      padding-top: 20px;
    }
  }
  a {
    font-size: 1.4rem;
    text-decoration: underline;
  }
}

.global-footer__copyright {
  padding-top: 40px;
  font-size: 1.2rem;
  text-align: center;
  small {
    font-size: 1.2rem;
  }
}
</style>