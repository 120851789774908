<template>
<div class="signup">
      <div class="signup__body">
        <center class="signup__desc">NTT東日本ビジネスID ログイン中</center>
        <p class="signup__desc">以下の項目の一部は、NTT東日本ビジネスIDに登録いただいているお客さま情報を表示しています。お客さま情報の変更が必要な場合は、<a class="signup__anchor" :href="mypageUrl" target="_blank">NTT東日本ビジネスIDマイページ</a>から変更してください。</p>
        <ButtonLink class="inquiries__button" type="signup-narrow" :href="loginUrlAddParams" @click.native="setFormId">変更した登録情報を更新</ButtonLink>
        <ButtonLink class="inquiries__button" type="login-narrow" @click.native="logout">NTT東日本ビジネスIDからログアウト</ButtonLink>
      </div>
    </div>
</template>


<script>
import ButtonLink from '@/components/button/Link';

export default {
  name: 'SignupAfter',
  components: {
    ButtonLink
  },
  props: [
    'query',
    'loginUrl'
  ],
  data() {
    return {
      mypageUrl: process.env.VUE_APP_NTT_EID_URL + 'NTTIDPFOnG01/usemyp001/usemyp00101',
      logoutUrl: '',
      loginUrlAddParams:''
    };
  },
  methods: {
    logout() {
      // ブラウザリロード時の処理を破棄
      window.removeEventListener('beforeunload', this.isReloadCookieSet);

      // ログインフラグをfalseに設定
      this.$cookies.set('isLogin', 'false');
      sessionStorage.setItem('isLogin', 'false');

      let query = new URLSearchParams(this.$route.query);
      this.$cookies.set('query', query.toString());

      // ログアウトURLを生成
      let logoutUrl =
      process.env.VUE_APP_BARISTA_LOGIN_URL + 'auth/realms/nttidpf_user/protocol/openid-connect/logout' +
      '?id_token_hint=' + this.$store.state.userinfo.id_token +
      '&state=' + this.$cookies.get('state') +
      '&post_logout_redirect_uri=' + process.env.VUE_APP_HOST_URL + 'callback?' + query

      // ログアウトURLへ遷移
      window.location.href = logoutUrl
    },
    isReloadCookieSet() {
      this.$cookies.set('isReload', 'true')
    },
    setFormId(){
      this.$cookies.set('formId', this.$route.query.formId);

      let query = new URLSearchParams(this.$route.query);
      this.$cookies.set('query', query.toString());
    }
  },
  mounted() {
    // promptをつけてBarista側にログイン中か確認する
    this.loginUrlAddParams = this.loginUrl + '&prompt=none'

    // ブラウザリロード時の処理を設定
    window.addEventListener('beforeunload', this.isReloadCookieSet);
  },
  destroyed() {
    // ブラウザリロード時の処理を破棄
    window.removeEventListener('beforeunload', this.isReloadCookieSet);
  },
};
</script>

<style lang="scss" scoped>
.signup{
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #CCC;
  .inquiries__button,.link--q{
    margin-top: 15px;
  }
}
.signup__body{
  background-color: #f0f0f0;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signup__desc{
  font-size: 16px;
  line-height: 1.63;
  padding-bottom: 10px;
  width: 100%;
}
.signup__anchor{
  color: #0074bf;
  text-decoration: underline;
  &:hover{
    color: #f49000;
  }
}
</style>
