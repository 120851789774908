<template>
  <div class="ntteast">
    <h1>ページ一覧</h1>
    <h2>法人のお客さまご相談・お問い合わせフォーム</h2>
    <table class="page-list">
      <thead>
        <tr>
          <th>ページ名</th>
          <th>URL</th>
          <th>備考</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>法人のお客さまご相談・お問い合わせフォーム ログイン前</td>
          <td><router-link to="/form-input1">/form-input1</router-link></td>
          <td></td>
        </tr>
        <tr>
          <td>法人のお客さまご相談・お問い合わせフォーム ログイン後</td>
          <td><router-link to="/form-input1_after">/form-input1_after</router-link></td>
          <td></td>
        </tr>
        <tr>
          <td>法人のお客さまご相談・お問い合わせフォーム バリデーション</td>
          <td><router-link to="/form-input1_varidation">/form-input1_varidation</router-link></td>
          <td></td>
        </tr>
        <tr>
          <td>2カラム</td>
          <td><router-link to="/form-input2">/form-input2</router-link></td>
          <td></td>
        </tr>
        <tr>
          <td>2カラム ログイン後</td>
          <td><router-link to="/form-input2_after">/form-input2_after</router-link></td>
          <td></td>
        </tr>
        <tr>
          <td>完了</td>
          <td><router-link to="/cvpage">/cvpage</router-link></td>
          <td></td>
        </tr>
        <tr>
          <td>エラー404</td>
          <td><router-link to="/error-404">/error-404</router-link></td>
          <td></td>
        </tr>
        <tr>
          <td>システムエラー</td>
          <td><router-link to="/error-system">/error-system</router-link></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <h1>テストログイン</h1>
    <a :href=login_url>{{login_url}}</a>
    <hr>
    <h1>APIの呼び出しテスト</h1>
      <button
        class="sample-button"
        @click="callApi('form?formId=pf0018inq&page_type=1')"
      >
        呼び出し
      </button>
      <hr>
    <h2>結果</h2>
    <pre style="font-size: 130%; color: #666699; background-color: #fcf4fe;"><code>{{result}}</code></pre>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      modalCansel: false,
      result: '',
      login_url: '',
    }
  },
  mounted: function(){
    /* ログインURLの生成 */

    // Store
    this.$store.commit('userinfo/addTest', 1)
    this.$loginfo(this.$store.state.userinfo.test)

    // stateをCookieに追加
    const state = this.$uuid.generate()
    this.$cookies.set('state', state);

    // ログインURL生成
    this.loginUrl =
      process.env.VUE_APP_BARISTA_LOGIN_URL + 'auth/realms/nttidpf_user/protocol/openid-connect/auth' +
      '?client_id=' + process.env.VUE_APP_BARISTA_CLIENT_ID +
      '&response_type=code' +
      '&scope=openid+profile+email+address+phone' +
      '&state=' + state +
      '&redirect_uri=' + process.env.VUE_APP_HOST_URL + 'callback%3FformId=' + this.$route.query.formId
  },
  methods: {
    // APIの呼び出しテスト
    callApi: function (url) {
        this.$axios
          .get(url)
          .then(response => {
              this.result = response.data
          })
    }
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-top: 10px;
}
.page-list {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  margin-bottom: 30px;
  th, td {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    padding: 5px 10px;
    font-size: 1.6rem;
  }
}
.sample-button {
  color: #fff;
  background-color: #eb6100;
  font-size: 20px;
}
.sample-button:hover {
  color: #fff;
  background: #f56500;
  font-size: 20px;
}
</style>
