<template>
  <header :class="className">
    <h1 class="header__title">
      <slot name="title"></slot>
    </h1>
    <h2 class="header__subtitle">
      <slot name="subtitle"></slot>
    </h2>
    <div class="header__lead">
      <TextBlock type="lead" class="header__lead-text">
        <slot name="text"></slot>
      </TextBlock>
    </div>
  </header>
</template>
<script>

import TextBlock from '@/components/text/Default';

export default {
  props: [ 'type' ],
  components: {
    TextBlock
  },
  computed: {
    className() {
      return this.type ? `header--${ this.type }` : 'header';
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  position: relative;
  width: calc(100% + 80px);
  margin-left: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 10px;
  @media screen and (max-width: $sp_point) {
    width: 100%;
    margin-left: auto;
    padding: 0 16px 24px;
  }
}
.header--finish {
  @extend.header;
  padding-bottom: 0;
  .header__title {
    font-size: 2.2rem;
  }
  .header__lead-text{
    border: 0;
    text-align: center;
    font-size: 1.8rem;
    padding-top: 0;
  }
}
.header__title {
  @include text-crop;
    display: block;
    font-weight: bold;
    font-size: 3.2rem;
    text-align: center;
}
.header__subtitle {
  @include text-crop;
    display: block;
    font-weight: bold;
    font-size: 2.2rem;
    padding-top: 50px;
    text-align: center;
}
.header__lead {
  width: 100%;
  text-align: center;
  padding-top: 6px;
  @media screen and (max-width: $sp_point) {
    padding-top: 10px;
    text-align: left;
  }
}
.header__lead-text {
  font-size: 18px;
  text-align: center;
  &:empty {
    display: none;
  }
}
</style>