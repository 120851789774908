<template>
  <header :class="className">
    <div class="header__lead">
      <TextBlock type="lead" class="header__lead-text" :html="screen_composition.form_text">
        <slot name="text"></slot>
      </TextBlock>
    </div>
  </header>
</template>
<script>
import TextBlock from "@/components/text/Default";

export default {
  props: [
          "type",
          "screen_composition"
          ],
  components: {
    TextBlock,
  },
  computed: {
    className() {
      return this.type ? `header--${this.type}` : "header";
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 24px;
  @media screen and (max-width: $sp_point) {
    width: auto;
    padding: 0 16px 24px;
  }
}
.header__lead {
  width: 100%;
  text-align: center;
  @media screen and (max-width: $sp_point) {
    text-align: left;
  }
}
.header__lead-text {
  padding-top: 0;
  text-align: left;
  &:empty {
    display: none;
  }
}
</style>
