<template>
<div class="logo-ntteast">
  <Retina
    dir="common"
    :name="imgName"
    extension="svg"
    width="141"
    height="32"
    alt="NTT東日本"
   />
</div>
</template>

<script>
import Retina from '@/components/img/Retina';

export default {
  name: 'NttEast',
  props: [ 'type' ],
  computed: {
    imgName() {
      return this.type ? `logo-ntte-${ this.type }` : 'logo-ntte';
    }
  },
  components: {
    Retina
  },
}
</script>

<style lang="scss" scoped>
.logo-ntteast {
  width: 141px;
  height: 32px;
  @media screen and (max-width: $sp_point) {
    width: 126px;
    height: 28px;
  }
}
</style>