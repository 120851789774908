<template>
  <a :href="url" class="link--q" target="_blank">NTT東日本ビジネスIDとは</a>
</template>

<script>

export default {
  name: 'Whats',
  props: [ 'type' ],
  computed: {
    imgName() {
      return this.type ? `logo-ntte-${ this.type }` : 'logo-ntte';
    }
  },
  components: {
  },
  data() {
    return {
      "url": process.env.VUE_APP_NTT_EID_URL + 'NTTIDPFOnG01/viefaq001/viefaq00101',
    };
  },
}
</script>

<style lang="scss" scoped>
%link {
  display: inline-flex;
  align-items: center;
  &::before {
    display: inline-block;
    margin-right: 10px;
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.link {
  @extend %link;

  font-size: 1.4rem;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  &::before {
    width: 6px;
    height: 12px;
    @include image-set(
      $path: "/shared/img/common/",
      $name: "arw-link",
      $extension: "png"
    );
  }
}

.link--q {
  @extend %link;
  font-size: 1.4rem;
  &::before {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    background-image: url(~@/assets/shared/img/common/icon-faq.svg);
    background-repeat: no-repeat;
  }
  &:hover{
    color: #f49000;
    text-decoration: underline;
  }
}
</style>
