import Vue from 'vue'
import VueRouter from "vue-router"
import Index from '@/pages/Index'

// お問い合わせ（追加情報あり）
import formInput1 from '@/pages/inquiries/form-input1'
import formInput1After from '@/pages/inquiries/form-input1_after'
import formInput from '@/pages/inquiries/form-input'
import formInputA from '@/pages/inquiries/form-inputA'
import formInput2 from '@/pages/inquiries/form-input2'
import formInput2After from '@/pages/inquiries/form-input2_after'
import formInputFinish from '@/pages/inquiries/cvpage'
import formInputFinishA from '@/pages/inquiries/cvpageA'
import formInputVaridation from '@/pages/inquiries/form-input1_varidation'
import error404 from '@/pages/inquiries/error-404'
import errorSystem from '@/pages/inquiries/error-system'

// バリスタコールバック用
import CallBack from '@/pages/callback/Index'
Vue.use(VueRouter)

let routes = [{
        path: '/callback',
        name: 'callBack',
        component: CallBack,
        meta: { title: 'Redirect' }
    },
    {
        path: '/',
        name: 'inquiries',
        component: formInput,
        meta: { title: '法人のお客さまご相談・お問い合わせフォーム｜NTT東日本', layout: 'inquiries' }
    },
    {
        path: '/a',
        name: 'inquiriesTypeA',
        component: formInputA,
        meta: { title: '法人のお客さまご相談・お問い合わせフォーム｜NTT東日本', layout: 'inquiries' }
    },
    {
        path: '/cvpage/',
        name: 'InquiriesFinish',
        component: formInputFinish,
        meta: { title: '法人のお客さまご相談・お問い合わせフォーム 完了｜NTT東日本', layout: 'InquiriesFinish' }
    },
    {
        path: '/cvpagea',
        name: 'InquiriesFinishTypeA',
        component: formInputFinishA,
        meta: { title: '法人のお客さまご相談・お問い合わせフォーム 完了｜NTT東日本', layout: 'InquiriesFinish' }
    },
    {
        path: '/error-system/',
        name: 'errorSystem',
        component: errorSystem,
        meta: { title: 'システムエラー｜NTT東日本', description: "NTT東日本のフォームです。", layout: 'inquiries1column' }
    },
    {
        path: '*',
        name: 'error404',
        component: error404,
        meta: { title: '404エラー｜NTT東日本', layout: 'inquiries1column' }
    },
];

let routes_debug = [{
        path: '/list',
        name: 'index',
        component: Index,
        meta: { title: 'テスト用ページ一覧' }
    },
    {
        path: '/form-input1/',
        name: 'inquiriesIndex',
        component: formInput1,
        meta: { title: '法人のお客さまご相談・お問い合わせフォーム｜NTT東日本', layout: 'inquiries1column' }
    },
    {
        path: '/form-input1_after/',
        name: 'inquiriesIndexAfter',
        component: formInput1After,
        meta: { title: '法人のお客さまご相談・お問い合わせフォーム ログイン後｜NTT東日本', layout: 'inquiries1column' }
    },
    {
        path: '/form-input1_varidation/',
        name: 'inquiriesVaridation',
        component: formInputVaridation,
        meta: { title: '法人のお客さまご相談・お問い合わせフォーム バリデーション｜NTT東日本', layout: 'inquiries1column' }
    },
    {
        path: '/form-input2/',
        name: 'inquiries2column',
        component: formInput2,
        meta: { title: '法人のお客さまご相談・お問い合わせフォーム 2カラム｜NTT東日本', layout: 'inquiries2column' }
    },
    {
        path: '/form-input2_after/',
        name: 'inquiries2columnAfter',
        component: formInput2After,
        meta: { title: '法人のお客さまご相談・お問い合わせフォーム 2カラム ログイン後｜NTT東日本', layout: 'inquiries2column' }
    },
];

if (process.env.VUE_APP_DEBUG == 'true') {
    Array.prototype.push.apply(routes, routes_debug)
}


const router = new VueRouter({
    mode: 'history',
    routes: routes
})

export default router;