<template>
  <div class="inquiries-layout">
    <div class="inquiries-layout__logo">
      <div class="inquiries-layout__logo-body">
        <NttEast />
      </div>
    </div>
    <main class="inquiries-layout__body--2column">
      <h1>{{ screen_composition.form_title }}</h1>
      <div class="inquiries-layout--2column" v-if="form_template !== 1">
        <Help type="default" :screen_composition="screen_composition" />
      </div>
      <router-view :form_items_list="form_items_list" />
      <ButtonSubmit class="inquiries__button" type="signup" to="/cvpage/">送信する</ButtonSubmit>
    </main>
    <FooterSimple />
  </div>
</template>

<script>
import NttEast from "@/components/logo/NttEast";
import FooterSimple from "@/components/common/FooterSimple";
import "@/assets/shared/sass/object/project/_inquiries.scss";
import Help from "@/components/detail/HelpArea";
import ButtonSubmit from "@/components/button/SubmitButton";

export default {
  props: ["type"],
  name: "inquiries2column",
  components: {
    NttEast,
    FooterSimple,
    Help,
    ButtonSubmit,
  },
  data() {
    return {
      form_items_list: [],
      screen_composition: [],
      screen_components: [],
      form_template: 0,
    };
  },
  methods: {},
  mounted() {
    this.$axios
      .get("form", {
        params: {
          formId: this.$route.query.formId ?? "pf0018inq",
          page_type: 1,
        },
      })
      .then((response) => {
        this.screen_components = response.data;
        this.screen_composition = response.data.screen_composition;
        this.form_items_list = response.data.form_items;
        this.form_template = response.data.screen_composition.form_template;
      })
      .catch({});
  },
};
</script>

<style lang="scss" scoped>
.button-link--signup {
  margin: 46px 70px 0 auto;
  @media screen and (max-width: $sp_point) {
    margin: 12px auto 0;
  }
}
h1 {
  width: 100%;
  display: block;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.92;
  text-align: center;
  color: #333;
  margin-bottom: 60px;
  @media screen and (max-width: $sp_point) {
    font-size: 2.2rem;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 22px;
    margin-bottom: 25px;
  }
}
.inquiries-layout__logo-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1100px;
  height: 80px;
  margin: 0 auto;
  @media screen and (max-width: $tablet_point) {
    width: auto;
    height: 56px;
    padding: 0 15px;
  }
}

.inquiries-layout__body {
  padding-bottom: 95px;
  width: 1100px;
  margin: 0 auto;
  @media screen and (max-width: $tablet_point) {
    width: auto;
    padding-bottom: 80px;
  }
}
.inquiries-layout__body--2column {
  @extend.inquiries-layout__body;
  display: flex;
  flex-wrap: wrap;
}
.inquiries-layout--2column {
  width: 530px;
  margin: 0 auto;
  @media screen and (max-width: $tablet_point) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: $sp_point) {
    width: 100%;
  }
}
.inquiries-layout__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-top: 1px solid $c-border;
}
.inquiries-layout__copyright {
  font-size: 1.4rem;
  font-weight: 300;
}
</style>
