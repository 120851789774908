<template>
  <!-- <div class="inquiries" v-if="this.isShow"> -->
  <div class="inquiries">
    <Header :screen_composition="screen_composition">
      <template v-slot:title>
        {{ screen_composition.form_title }}
      </template>
      <template v-slot:text>
        {{ screen_composition.form_text }}
      </template>
    </Header>

    <div v-if="$store.state.userinfo.sub && form_items[0]" class="signup__desc">
      <p>
        以下の項目の一部は、NTT東日本ビジネスIDに登録いただいているお客さま情報を表示しています。登録情報を変更する場合は、<a class="signup__anchor"
          :href="mypageUrl">NTT東日本ビジネスIDマイページ</a>にて変更したうえで、再度、本フォームにて問い合わせを行ってください。
      </p>
    </div>

    <template v-for="(form_item, index) in form_items">
      <div class="inquiries__section" v-if="isDrawn(form_item)" :key="index">
        <div :class="isLeftSide(form_item) ? 'form__group' : ''">
          <!-- form__group内のform__itemは半分のサイズになる -->
          <div class="form__item">
            <h3 class="title-label--required">
              <p v-if="form_item.requiredflag" class="label--require">必須</p>
              {{ form_item.name_label }}
            </h3>

            <!-- テキスト -->
            <template v-if="form_item.input_type === 1">
              <FormText :name="form_item.name_class" :id="form_item.name_class" :required="form_item.requiredflag"
                v-model="$data[`form${index + 1}text`]" :state="errorMessages[`form${index + 1}`] ? 'error' : ''"
                :disabled="Boolean(disabled[form_item.name_class])" :maxlength="form_item.max_length" />
            </template>

            <!-- テキストエリア -->
            <template v-else-if="form_item.input_type === 5">
              <FormTextArea :state="errorMessages[`form${index + 1}`] ? 'error' : ''" class="inquiries__textarea"
                v-model="$data[`form${index + 1}text`]" :maxlength="form_item.max_length" />
            </template>

            <!-- プルダウンリスト -->
            <FormSelect v-else-if="form_item.input_type === 4" :option-list="form_item.options"
              v-model="$data[`form${index + 1}text`]" :value="$data[`form${index + 1}text`]"
              :state="errorMessages[`form${index + 1}`] ? 'error' : ''" />

            <!-- カレンダー -->
            <template v-else-if="form_item.input_type === 6">
              <FormDate v-model="$data[`form${index + 1}text`]"
                :state="errorMessages[`form${index + 1}`] ? 'error' : ''" />
            </template>

            <!-- ラジオボタン -->
            <div class="form__check-wrap" v-else-if="form_item.input_type === 2">
              <template v-for="(option, index_) in form_item.options">
                <FormRadio :key="index_" :name="form_item.name_class"
                  :id="form_item.name_class + String(index_ + 1) + option" v-model="$data[`form${index + 1}text`]"
                  :val="option" :state="errorMessages[`form${index + 1}`] ? 'error' : ''">
                  {{ option }}
                </FormRadio>
              </template>
            </div>

            <!-- チェックボックス -->
            <div class="form--checkboxes" v-else-if="form_item.input_type === 3">
              <div class="form__check-wrap">
                <FormCheckBox v-for="(option, index_) in form_item.options" :key="option" name="checkbox1"
                  :id="`checkbox${index}_${index_}`" :checked="$data[`form${index + 1}checkboxes`][index_]" @change="
                    $data[`form${index + 1}checkboxes`].splice(
                      index_,
                      1,
                      $event
                    )
                  " :state="errorMessages[`form${index + 1}`] ? 'error' : ''">
                  {{ option }}
                </FormCheckBox>
              </div>
            </div>

            <!-- エラーメッセージ -->
            <ErrorMessage v-if="errorMessages[`form${index + 1}`]">
              {{ errorMessages[`form${index + 1}`] }}
            </ErrorMessage>
          </div>

          <!-- 右側半分に別のテキストボックスを表示する（名、メイ、お客様ID入力部分） -->
          <template v-if="isRightSide(form_items[index + 1])">
            <div class="form__item">
              <h3 class="title-label--required">
                <p v-if="form_items[index + 1].requiredflag" class="label--require">
                  必須
                </p>
                {{ form_items[index + 1].name_label }}
              </h3>

              <FormText :name="form_items[index + 1].name_class" :id="form_items[index + 1].name_class"
                :required="form_items[index + 1].requiredflag" v-model="$data[`form${index + 2}text`]"
                :state="errorMessages[`form${index + 2}`] ? 'error' : ''"
                :disabled="Boolean(disabled[form_items[index + 1].name_class])"
                :maxlength="form_items[index + 1].max_length" />
              <ErrorMessage v-if="errorMessages[`form${index + 2}`]">
                {{ errorMessages[`form${index + 2}`] }}
              </ErrorMessage>
            </div>
          </template>
        </div>
      </div>
    </template>
    <div class="inquiries__section" v-if="form_items[0]">
      <div class="form__item">
        <h3 class="title-label--required">
          <p class="label--require">必須</p>
          個人情報の取り扱い
        </h3>

        <div class="form--checkboxes">
          <div class="privacy">
            <div class="privacy__body">
              <p class="privacy__text" v-if="screen_composition.form_personal_text"
                v-html="screen_composition.form_personal_text" />
              <p class="privacy__text" v-else>
                当社は、お客様の個人情報を、<a class="privacy__anchor" href="https://business.ntt-east.co.jp/formpolicy.html"
                  target="_blank">「お問い合わせ等フォームにおける個人情報の取り扱い」</a>に定めるところに基づき取り扱い、営業上のご案内（広告・宣伝のメールマガジン含む）を行う場合があります。
              </p>
            </div>
            <div class="privacy__foot">
              <div class="privacy__check">
                <FormCheckBox name="privacy" id="privacy" :checked="isAccepted" @change="isAccepted = $event"
                  :state="errorMessages.isAccepted ? 'error' : ''">
                  同意します
                </FormCheckBox>
                <ErrorMessage v-if="errorMessages.isAccepted">
                  {{ errorMessages.isAccepted }}
                </ErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="inquiries__section" style="display:none">
      <div class="random-id">
        <div class="form__item">
          <h3 class="title-label--required">ランダムID</h3>
          <input type="text" name="RandomID" id="RandomID" placeholder="" maxlength="21845" class="form-text" value="">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Header from "@/components/common/HeaderInquiries2";
import FormTextArea from "@/components/form/TextArea";
import FormCheckBox from "@/components/form/CheckBox";
import FormRadio from "@/components/form/Radio";
import FormText from "@/components/form/Text";
import FormSelect from "@/components/form/Select";
// import FormLabel from "@/components/form/label";
import FormDate from "@/components/form/Date";
import ErrorMessage from "@/components/text/ErrorMessage";

const MAX_FORMS = 100;
const MAX_OPTIONS = 25;

const REG_KATAKANA = /^[ァ-ヴー]+$/;
const REG_POSTAL_CODE = /^[0-9]{7}$/;
const REG_EMAIL = /^[a-zA-Z0-9!#$%&*+\\\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&*+\\\-/=?^_`{|}~]+)*@([a-zA-Z0-9](([a-zA-Z0-9-])*[a-zA-Z0-9])*\.)+[a-zA-Z]{2,}$/;
const REG_PHONE_NUM = /^[0-9]{10,11}$/;
const REG_NUMERIC = /^[0-9]+$/;

const REG_RULES = [
  {
    regExp: REG_KATAKANA,
    targets: [
      "LastNameFurigana",
      "FirstNameFurigana",
      "DOMlast_nameFuriganaApplicant",
      "DOMfirst_nameFuriganaApplicant",
    ],
    errorMessage: "全角カタカナで入力してください。",
  },
  {
    regExp: REG_POSTAL_CODE,
    targets: ["zip", "DOMUseZip"],
    errorMessage: "7桁の半角数字で入力してください。",
  },
  {
    regExp: REG_EMAIL,
    targets: ["email", "DOMApplicantEmail"],
    errorMessage: "形式が不正です。",
  },
  {
    regExp: REG_PHONE_NUM,
    targets: [
      "phone",
      "PhoneNumber2",
      "OtherPhoneNumbers",
      "DOMHikariPhone",
      "DOMContractPhone",
      "DOMApplicantPhone",
      "DOMUsePhone",
      "DOMFaxPhone",
    ],
    errorMessage: "10-11桁の半角数字で入力してください。",
  },
  {
    regExp: REG_NUMERIC,
    targets: ["DOMContractIDNumber"],
    errorMessage: "半角数字で入力してください。",
  },
];

/*  配列の配列を、要素を並べた一次元配列に変換する
    eg. concatenateList([["a", "b"], ["c", "d"]]) => ["a", "b", "c", "d"] */
function concatenateLists(lists) {
  const arr = [].concat(...lists);
  return arr;
}

export default {
  head: {
    title: function () {
      return {
        inner: this.screen_composition.form_title ?? "",
        separator: "|",
        complement: "NTT東日本",
      };
    },
    meta: [
      { property: "og:title", content: "フォーム | NTT東日本" },
      { name: "referrer", content: "no-referrer-when-downgrade" },
      {
        "http-equiv": "Referrer-Policy",
        content: "no-referrer-when-downgrade",
      },
    ],
    link: function () {
      return [
        {
          rel: "canonical",
          href: `https://form.business.ntt-east.co.jp/a?formId=${this.formId ?? ""
            }`,
          id: "canonical",
        },
      ];
    },
  },
  name: "FormInput",
  props: ["type", "screen_composition", "form_items", "isClicked", "formId"],
  components: {
    Header,
    FormTextArea,
    FormCheckBox,
    FormRadio,
    FormText,
    // FormLabel,
    FormDate,
    FormSelect,
    ErrorMessage,
  },
  data() {
    /* 以下のような形式になる
    data = {
      ...,
      errorMessages: {
        isAccepted: ""
        form1: "",
        form2: "",
        ...,
        form21: ""
      }
      form1text: "",
      form1checkboxes: [false, false, ..., false],
      form2text: "",
      form2checkboxes: [false, false, ..., false],
      ...,
      form21text: "",
      form21checkboxes: [false, false, ..., false],
    }
    */

    const data = {
      disabled: {
        first_name: this.$store.state.userinfo.given_name,
        last_name: this.$store.state.userinfo.family_name,
        FirstNameFurigana: this.$store.state.userinfo.given_name_kana,
        LastNameFurigana: this.$store.state.userinfo.family_name_kana,
        email: this.$store.state.userinfo.email,
        zip: this.$store.state.userinfo.address.postal_code,
        state: this.$store.state.userinfo.address.region,
        city: this.$store.state.userinfo.address.locality,
        address_one: this.$store.state.userinfo.address.street_address,
        phone: this.$store.state.userinfo.phone_number,
        ClientForm: this.$store.state.userinfo.company_name,
      },
      isAccepted: false,
      errorMessages: {
        isAccepted: "",
      },
      isLoggedIn: undefined,
      firstNameItems: [
        "first_name",
        "FirstNameFurigana",
        "DOMfirst_nameApplicant",
        "DOMfirst_nameFuriganaApplicant",
      ],
      lastNameItems: [
        "last_name",
        "LastNameFurigana",
        "DOMlast_nameApplicant",
        "DOMlast_nameFuriganaApplicant",
      ],
      customerIdPulldowns: ["CustomObject1612417356185", "Custom_2"],
      mypageUrl: process.env.VUE_APP_NTT_EID_URL + "NTTIDPFOnG01/usemyp001/usemyp00101",
      // isShow: false,
    };

    for (let i = 0; i < MAX_FORMS; i++) {
      data["form" + String(i + 1) + "text"] = "";
      data["form" + String(i + 1) + "checkboxes"] = Array(MAX_OPTIONS).fill(
        false
      ); // [false, false, ... , false].length => 15

      data.errorMessages["form" + String(i + 1)] = "";
    }

    return data;
  },
  computed: {
    postParams() {
      let params = {
        formhandler_url: this.screen_composition.pardot_formhandler, // 送信先のURL
        form_items: {
          // ログイン済みならstoreから自動取得
          uid: this.$store.state.userinfo.uid,

          // クエリパラメータから取得
          NewForm: this.$route.query.NewForm,
          AdvertisingInflux: this.$route.query.AdvertisingInflux,

          // 同意済みでないと送信ボタンが押下できないため、固定値
          PersonalInfoConsentCheck: "同意する",
        },
      };
      const prop01Array = Array.from({ length: 3 }, () => "");
      const prop02Array = Array.from({ length: 4 }, () => "");

      for (let i = 0; i < this.form_items.length; i++) {
        let form_item = this.form_items[i];
        let param = "";

        if (form_item.name_class == "ClientForm") {
          prop01Array[0] = this[`form${i + 1}text`];
        }
        if (form_item.name_class == "Category") {
          prop01Array[1] = this[`form${i + 1}text`];
        }
        if (
          form_item.name_label == "業種" ||
          form_item.name_label == "業種区分"
        ) {
          prop01Array[2] = this[`form${i + 1}text`];
        }
        if (form_item.name_class == "Position") {
          prop02Array[0] = this[`form${i + 1}text`];
        }
        if (form_item.name_class == "TitleSectionViaForm") {
          prop02Array[1] = this[`form${i + 1}text`];
        }
        if (form_item.name_class == "DepartmentSectionViaForm") {
          prop02Array[2] = this[`form${i + 1}text`];
        }
        if (form_item.name_class == "PositionOnITservices") {
          prop02Array[3] = this[`form${i + 1}text`];
        }

        if (form_item.input_type == 3) {
          // チェックボックスの場合
          for (let j = 0; j < this[`form${i + 1}checkboxes`].length; j++) {
            // 値の配列を最初から順番に見て
            if (this[`form${i + 1}checkboxes`][j]) {
              // チェックボックスのj番目がtrueだったら
              param += param
                ? `,${form_item.options[j]}`
                : form_item.options[j];
            }
          }
        } else {
          param = this[`form${i + 1}text`];
        }

        params.form_items[form_item.name_class] = param;
      }
      this.$cookies.set("prop01", prop01Array.join("・"));
      this.$cookies.set("prop02", prop02Array.join("・"));

      return params;
    },
    leftSideNameClasses() {
      const nameClassLists = [this.lastNameItems];

      return concatenateLists(nameClassLists);
    },
    rightSideNameClasses() {
      const nameClassLists = [this.firstNameItems];

      return concatenateLists(nameClassLists);
    },
  },
  async mounted() {
    this.$loginfo("visit form-inputA");

    // stateをCookieに追加
    if (!this.$cookies.get("state")) {
      const state = this.$uuid.generate();
      this.$cookies.set("state", state);
    }
    const state = this.$cookies.get("state");

    this.$cookies.set("formId", this.$route.query.formId);

    let query = new URLSearchParams(this.$route.query);
    let encodeQuery = encodeURIComponent(query.toString());
    this.$cookies.set("query", query.toString());
    const currentUrl = new URL(location);
    if (!currentUrl.searchParams.has("type")) {
      this.$cookies.set("isReload", "true");
    }

    // ログインURL生成
    this.loginUrl =
      process.env.VUE_APP_BARISTA_LOGIN_URL +
      "auth/realms/nttidpf_user/protocol/openid-connect/auth" +
      "?client_id=" +
      process.env.VUE_APP_BARISTA_CLIENT_ID +
      "&response_type=code" +
      "&scope=openid+profile+email+address+phone+given_name_kana+family_name_kana+company_name" +
      "&state=" +
      state +
      "&redirect_uri=" +
      process.env.VUE_APP_HOST_URL +
      "callback?" +
      encodeQuery;
    // '&redirect_uri=' + process.env.VUE_APP_HOST_URL + 'callback?formId=' + this.$route.query.formId

    await this.redirectCheck();

    this.isShow = true;
  },
  methods: {
    redirectCheck() {
      let redirectUrl = this.loginUrl + "&prompt=none";

      // リロードが行われた or 別ウィンドウで開いた場合にリダイレクト
      if (
        this.$cookies.get("isReload") == "true" ||
        (this.$cookies.get("isLogin") == "true" &&
          sessionStorage.getItem("isLogin") != "true")
      ) {
        window.location.href = redirectUrl;
      }
      return;
    },
    validationCheck() {
      let isErrors = []; // この配列内の要素が全てfalseなら、送信ボタンの押下を許可する

      // 各form項目ごとにチェック
      for (let i = 0; i < this.form_items.length; i++) {
        let form_item = this.form_items[i];
        let errorMessage;

        // チェックボックスかどうかを判断して、値のチェックを行う
        if (form_item.input_type === 3) {
          errorMessage = this.errorMessageCheckBoxes(
            form_item,
            this[`form${i + 1}checkboxes`]
          );
        } else {
          errorMessage = this.errorMessageText(
            form_item,
            this[`form${i + 1}text`]
          );
        }

        isErrors.push(Boolean(errorMessage));

        // 送信ボタン押下済みなら、エラーメッセージを表示する
        if (this.isClicked) {
          this.errorMessages[`form${i + 1}`] = errorMessage;
        }
      }

      // 個人情報の取り扱いのチェック
      this.checkAccepted();
      isErrors.push(!this.isAccepted);

      // バリデーションチェックが全て通っているかどうかをチェック
      const allowsSubmit = !isErrors.includes(true);

      // 上位コンポーネント(layout)に値を送信
      this.$emit("updateParams", this.postParams, allowsSubmit);
      return;
    },
    // テキストのバリデーションチェック
    errorMessageText(form_item, textModel) {
      // 必須チェック
      if (!textModel) {
        if (form_item.requiredflag) {
          return "必須項目です。";
        } else {
          return "";
        }
      }

      // 文字数チェック
      if (form_item.max_length) {
        if (textModel.length > form_item.max_length) {
          return "文字数が多すぎます。";
        }
      }

      // 正規表現チェック
      for (let i = 0; i < REG_RULES.length; i++) {
        const reg_rule = REG_RULES[i];

        if (reg_rule.targets.includes(form_item.name_class)) {
          // 対象のname_classを持っているなら
          if (reg_rule.regExp.test(textModel) === false) {
            // 正規表現チェックをして
            return reg_rule.errorMessage; // 既定の形式を満たさないのなら、エラーメッセージを返す
          }
        }
      }

      // 特殊チェック（お客様ID)
      if (form_item.name_class === "DOMContractIDNumber") {
        for (let i = 0; i < this.form_items.length; i++) {
          if (
            this.customerIdPulldowns.includes(this.form_items[i].name_class)
          ) {
            const customer_id_category = this[`form${i + 1}text`];
            let id_length_limit = 0;

            if (customer_id_category === "CAF") {
              id_length_limit = 10;
            } else if (customer_id_category === "COP") {
              id_length_limit = 8;
            } else {
              continue;
            }

            if (textModel.length !== id_length_limit) {
              return `${customer_id_category}を選択時は数値${id_length_limit}桁で入力してください。`;
            }
          }
        }
      }

      return "";
    },
    // チェックボックスのバリデーションチェック
    errorMessageCheckBoxes(form_item, checkboxesModel) {
      // 必須チェック
      if (form_item.requiredflag) {
        if (!checkboxesModel.includes(true)) {
          return "必須項目です。";
        }
      }

      return "";
    },
    // 個人情報の取り扱いに同意したかどうかを確認
    checkAccepted() {
      let errorMessage = "";

      // 送信ボタンが押下済みなら、エラーメッセージを出力
      if (this.isClicked) {
        if (this.isAccepted) {
          errorMessage = "";
        } else {
          errorMessage = "必須項目です。";
        }
      }

      this.errorMessages.isAccepted = errorMessage;
      return;
    },
    // ログイン済みなら、ユーザー情報を自動で入力する
    fillUserInfo() {
      // 対応表を定義 {name_class: ストアの値}
      const stored_user_info = {
        first_name: this.$store.state.userinfo.given_name,
        last_name: this.$store.state.userinfo.family_name,
        FirstNameFurigana: this.$store.state.userinfo.given_name_kana,
        LastNameFurigana: this.$store.state.userinfo.family_name_kana,
        email: this.$store.state.userinfo.email,
        zip: this.$store.state.userinfo.address.postal_code,
        state: this.$store.state.userinfo.address.region,
        city: this.$store.state.userinfo.address.locality,
        address_one: this.$store.state.userinfo.address.street_address,
        phone: this.$store.state.userinfo.phone_number,
        ClientForm: this.$store.state.userinfo.company_name,
        uid: this.$store.state.userinfo.uid,
      };

      for (let i = 0; i < this.form_items.length; i++) {
        const form_item = this.form_items[i]; // 該当するフォームの項目に

        if (stored_user_info[form_item.name_class]) {
          // 対応している値がstoreに定義されていれば
          this[`form${i + 1}text`] = stored_user_info[form_item.name_class]; // 値を入力する
        }
      }

      this.$nextTick(() => {
        // email欄の取得
        let email_element = document.getElementById("email");

        if(email_element != null){
          // inputイベントの設定
          let input_event = new Event( "input", {"bubbles":false, "cancelable":true});

          // イベントを発生させる
          email_element.dispatchEvent(input_event);
        }
      });
    },
    /* 右側に設置する要素は、動的出し分けのメインロジックで描画しない。
       特に関係なければそのまま描画する */
    isDrawn(form_item) {
      const isSkipped = this.rightSideNameClasses.includes(
        form_item.name_class
      );

      return !isSkipped;
    },
    // カレンダー、姓は左側に配置（=form__group内の第一要素）
    // form__group内のform__itemは半分のサイズになる
    isLeftSide(form_item) {
      if (this.leftSideNameClasses.includes(form_item.name_class)) {
        return true;
      }

      return false;
    },
    isRightSide(form_item) {
      if (!form_item) {
        return false;
      }

      if (this.rightSideNameClasses.includes(form_item.name_class)) {
        return true;
      }

      return false;
    },
  },
  watch: (function () {
    function validationCheck() {
      this.validationCheck();
    }

    let watch = {
      screen_composition: function () {
        this.$emit("updateHead"); // metaタグを再描画する
      },
      formId: function () {
        this.$emit("updateHead");
      },

      isClicked: validationCheck,
      form_items: function () {
        this.validationCheck();
        this.fillUserInfo();
      },
      isAccepted: validationCheck,
    };

    for (let i = 0; i < MAX_FORMS; i++) {
      watch["form" + String(i + 1) + "text"] = validationCheck;
      watch["form" + String(i + 1) + "checkboxes"] = validationCheck;
    }

    return watch;
  })(),
};
</script>

<style lang="scss" scoped>
.signup__desc {
  font-size: 16px;
  line-height: 1.63;
  padding-bottom: 10px;
  width: 100%;

  @media screen and (max-width: $sp_point) {
    width: auto;
    padding: 0 16px 24px;
  }
}

.signup__anchor {
  color: #0074bf;
  text-decoration: underline;

  &:hover {
    color: #f49000;
  }
}

.inquiries {
  width: 530px;
  margin: 0 auto;

  @media screen and (max-width: $sp_point) {
    width: 100%;
    margin-top: 22px;
    margin-bottom: 70px;
  }
}

.privacy {
  width: 100%;
  height: auto;
  position: relative;
  overflow: auto;
  border: solid 1px #ccc;
  padding: 12px 20px 20px;
}

.privacy__body {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #ccc;
  word-break: break-all;
}

.privacy__text {
  font-size: 16px;
  color: #333;
  line-height: 1.69;
}

.privacy__anchor {
  color: #0074bf;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.privacy__check {
  display: flex;
  justify-content: center;
}

.title-label--required {
  display: inline-block;
  padding: 0 0 12px;
  font-weight: bold;
  font-size: 1.4rem;
}
</style>
